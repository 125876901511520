import { getEnv } from '@/env';
import { capitalize } from 'lodash';
import { CreateClientParams } from 'contentful';

const globalContentfulConfig = (): CreateClientParams => ({
  space: getEnv('CONTENTFUL_GLOBAL_SPACE_ID') || '',
  accessToken: getEnv('CONTENTFUL_GLOBAL_ACCESS_TOKEN') || '',
  environment: getEnv('CONTENTFUL_GLOBAL_ENVIRONMENT') || 'master',
});

export const globalExtraToken = (): string => {
  const global = globalContentfulConfig();
  const extraTokens = {
    spaces: {
      [global.space]: global.accessToken,
    },
  };

  return btoa(JSON.stringify(extraTokens));
};

export const resourceLink = (typeName: string): string => {
  const global = globalContentfulConfig();
  return `${typeName}_${global.space}_${capitalize(global.environment)}`;
};
