import { GetterTree } from 'vuex';
import { ContentfulInfoState } from '@/store/contentful-info';

export const getters: GetterTree<ContentfulInfoState, any> = {
  globalLocales: (state: ContentfulInfoState): Array<string> => {
    return state.global.locales;
  },
  localLocales: (state: ContentfulInfoState): Array<string> => {
    return state.local.locales;
  },
  globalLocaleMap: (state: ContentfulInfoState): Record<string, string> => {
    return state.global.localeMap;
  },
  localLocaleMap: (state: ContentfulInfoState): Record<string, string> => {
    return state.local.localeMap;
  },
};
