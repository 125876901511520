import { SustainabilityStrategyGraphicFragment } from '../../../types/contentful-api';
import { GenericFactory } from '@/models/factories/GenericFactory';
import { DKSustainabilityStrategyGraphicFragment } from '@/models/DKSustainabilityStrategyGraphicFragment';

export class SustainabilityStrategyGraphicFragmentFactory extends GenericFactory<
  SustainabilityStrategyGraphicFragment,
  DKSustainabilityStrategyGraphicFragment
> {
  contentType = 'SustainabilityStrategyGraphicFragment';
  requiredProperties = ['shortTitle'];

  protected map(
    source: SustainabilityStrategyGraphicFragment
  ): DKSustainabilityStrategyGraphicFragment {
    return Object.assign({} as DKSustainabilityStrategyGraphicFragment, source);
  }
}
