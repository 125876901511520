import { EventOverviewFragment } from '../../../types/contentful-api';
import { GenericFactory } from '@/models/factories/GenericFactory';
import { DKLink } from '@/models/DKLink';
import { LinkFactory } from '@/models/factories/LinkFactory';
import { PropertyRequiredError } from '@/models/factories/PropertyRequiredError';
import { DKEventOverviewFragment } from '@/models/DKEventOverviewFragment';

export class EventOverviewFragmentFactory extends GenericFactory<
  EventOverviewFragment,
  DKEventOverviewFragment
> {
  contentType = 'EventOverviewFragment';
  requiredProperties = ['shortTitle', 'title'];

  protected map(source: EventOverviewFragment): DKEventOverviewFragment {
    let link: DKLink | undefined;

    if (source.link) {
      const linkFactory = new LinkFactory(this.locale);
      try {
        link = linkFactory.create(source.link);
      } catch (e: unknown) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate Link');
          console.warn(e.message);
        } else {
          throw e;
        }
      }
    }
    return Object.assign({} as DKEventOverviewFragment, { ...source, link });
  }
}
