import { MutationTree } from 'vuex';
import { SearchState } from '@/store/search/index';
import { unifySearchResults } from '@/helpers/unify-search-results';
import { DKSearchResults } from '@/store/search/models/DKSearchResults';
import { LOCALE } from '@/store/app/models/Locale';
import { EqsNewsItem } from '@dormakaba/search-type-definitions/dist/eqs/item';

export const mutations: MutationTree<SearchState> = {
  setSearchLoading: (state: SearchState) => {
    state.searchResults.loading = true;
  },
  setSearchResults: (
    state: SearchState,
    payload: { searchResults: DKSearchResults; locale: LOCALE }
  ) => {
    state.searchResults.loading = false;
    state.searchResults.data = unifySearchResults(
      payload.searchResults,
      payload.locale
    );
  },
  addSearchResults: (
    state: SearchState,
    payload: { searchResults: DKSearchResults; locale: LOCALE }
  ) => {
    state.searchResults.loading = false;
    state.searchResults.data = [
      ...state.searchResults.data,
      ...unifySearchResults(payload.searchResults, payload.locale),
    ];
  },
  clearSearchResults: (state: SearchState) => {
    state.searchResults.loading = false;
    state.searchResults.data = [];
    state.searchResults.hasMore = false;
  },
  setHasMore: (state: SearchState, payload) => {
    state.searchResults.hasMore =
      payload.total > 0 &&
      payload.limit < payload.total &&
      payload.limit === payload.itemsLength;
  },

  // news search (algolia)
  seNewsSearchLoading: (state: SearchState) => {
    state.newsResults.loading = true;
  },
  setNewsSearchResults: (
    state: SearchState,
    payload: { newsResults: EqsNewsItem[]; locale: LOCALE }
  ) => {
    state.newsResults.loading = false;
    const uniquePreviousRes = state.newsResults.data.filter(
      (item) => !payload.newsResults.includes(item)
    );
    const uniqueNewRes = payload.newsResults.filter(
      (item) => !state.newsResults.data.includes(item)
    );
    const uniqueItems = [...uniquePreviousRes, ...uniqueNewRes];
    state.newsResults.data = uniqueItems;
  },
  clearNewSearchResults: (state: SearchState) => {
    state.newsResults.loading = false;
    state.newsResults.data = [];
    state.newsResults.hasMore = false;
  },
  setNewsHasMore: (state: SearchState, payload) => {
    state.newsResults.hasMore =
      payload.total > 0 && payload.itemsLength < payload.total;
  },
};
