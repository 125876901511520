import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-25057eb2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  itemscope: "",
  itemtype: "http://schema.org/SiteNavigationElement",
  class: "contact-button-list"
}
const _hoisted_2 = { itemprop: "name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DKLink = _resolveComponent("DKLink")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contactNavigationTree, (contactNavigationTreeItem) => {
      return (_openBlock(), _createElementBlock("li", {
        class: "contact-button-list__list-item",
        key: contactNavigationTreeItem.id
      }, [
        _createVNode(_component_DKLink, {
          class: _normalizeClass(["dk-link--title", {
          'dk-link--inverted': _ctx.isInverted,
        }]),
          onClick: _withModifiers(($event: any) => (_ctx.$emit('clickContactLink', contactNavigationTreeItem)), ["prevent"]),
          href: contactNavigationTreeItem.path,
          target: contactNavigationTreeItem.target,
          itemprop: "url"
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_2, _toDisplayString(contactNavigationTreeItem.label), 1)
          ]),
          _: 2
        }, 1032, ["class", "onClick", "href", "target"])
      ]))
    }), 128))
  ]))
}