import { GenericFactory } from '@/models/factories/GenericFactory';
import { DKNewsItemCategory } from '@/models/DKNewsItemCategory';
import { EqsNewsItemCategory } from '@dormakaba/search-type-definitions/dist/eqs/item';

export class EqsNewsCategoryFactory extends GenericFactory<
  EqsNewsItemCategory,
  DKNewsItemCategory
> {
  contentType = 'EqsNewsCategory';
  requiredProperties = ['name', 'slug'];

  protected map(source: EqsNewsItemCategory): DKNewsItemCategory {
    return Object.assign({} as DKNewsItemCategory, source);
  }
}
