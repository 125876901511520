<template>
  <div
    itemprop="hasPart"
    itemscope
    itemtype="http://schema.org/SiteNavigationElement"
    :class="[
      'dk-navbar',
      `dk-navbar--${type}`,
      {
        'dk-navbar--open': isOpen,
      },
    ]"
  >
    <div class="dk-navbar__inner">
      <a
        v-if="!isOpen"
        class="dk-navbar__link dk-navbar__logo"
        :href="homeHref"
        @click.prevent="$emit('clickHome')"
        itemprop="url"
      >
        <DKLogo type="group" :color="logoColor" itemprop="logo" />
      </a>
      <ul class="dk-navbar__nav">
        <slot />
      </ul>
      <ul v-if="isDesktop" class="dk-navbar__nav-actions">
        <slot name="actions" />
      </ul>
    </div>
    <slot name="brand" />
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-facing-decorator';
import { DKLogo } from '@dormakaba/dormakaba-components';
import { useMq } from 'vue3-mq';
import { NavbarTypes } from '@/models/navigation/NavbarTypes';

@Component({
  components: {
    DKLogo,
  },
  emits: ['clickHome'],
})
export default class Navbar extends Vue {
  @Prop({ default: NavbarTypes.Secondary }) type!: NavbarTypes;
  @Prop({ default: false }) isOpen!: boolean;
  @Prop() homeHref!: string;

  get isDesktop(): boolean {
    return this.$isDesktop(this.$mq);
  }

  get isTransparent() {
    return this.type === NavbarTypes.Transparent;
  }

  get logoColor() {
    return this.isTransparent ? 'white' : 'corporative';
  }

  created() {
    this.$mq = useMq();
  }
}
</script>
