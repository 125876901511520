// @ts-ignore
import { inject, provide } from 'vue';
import VueApollo from 'vue-apollo';
import ApolloSSR from 'vue-apollo/ssr';

const StoreSymbol = Symbol('store');

export function _createStore(apolloProvider: VueApollo) {
  return ApolloSSR.getStates(apolloProvider);
}

export function provideStore(store: any) {
  provide(StoreSymbol, store);
}

export default function useStore(): any {
  const store = inject(StoreSymbol);
  if (!store) {
    throw Error('no store provided');
  }
  return store;
}
