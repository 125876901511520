import {
  GenericAccordionElement,
  GenericAccordionFragment,
} from '../../../types/contentful-api';
import { GenericFactory } from '@/models/factories/GenericFactory';
import { DKGenericAccordionFragment } from '@/models/DKGenericAccordionFragment';
import { PropertyRequiredError } from '@/models/factories/PropertyRequiredError';
import { DKGenericAccordionElement } from '@/models/DKGenericAccordionElement';
import { GenericAccordionElementFactory } from '@/models/factories/GenericAccordionElementFactory';

export class GenericAccordionFragmentFactory extends GenericFactory<
  GenericAccordionFragment,
  DKGenericAccordionFragment
> {
  contentType = 'GenericAccordionFragment';
  requiredProperties = ['shortTitle', 'title'];

  protected map(source: GenericAccordionFragment): DKGenericAccordionFragment {
    const genericAccordionElements: DKGenericAccordionElement[] = [];

    if (source.genericAccordionElementsCollection?.items) {
      const items: GenericAccordionElement[] = source
        .genericAccordionElementsCollection.items as GenericAccordionElement[];
      const genericAccordionElementFactory = new GenericAccordionElementFactory(
        this.locale
      );

      items.forEach((genericAccordionElement: GenericAccordionElement) => {
        try {
          genericAccordionElements.push(
            genericAccordionElementFactory.create(genericAccordionElement)
          );
        } catch (e) {
          if (e instanceof PropertyRequiredError) {
            console.warn('Could not generate GenericAccordionElement');
            console.warn(e);
          } else {
            throw e;
          }
        }
      });
    }

    return Object.assign({} as DKGenericAccordionFragment, {
      ...source,
      genericAccordionElements,
    });
  }
}
