import { DKSpaceOrigin, DKSpaceOriginType } from '@/models/DKSpaceOrigin';
import gql from 'graphql-tag';

export const popupQuery = (origin: DKSpaceOrigin) => {
  // Determine the correct locale for the deLink based on the origin
  const deLinkLocale =
    origin === DKSpaceOriginType.GLOBAL_SPACE ? 'de' : 'de-DE';

  return gql`
    query ($id: String!, $locale: String = "en-US", $preview: Boolean = false) {
      popup(id: $id, locale: $locale, preview: $preview) {
        sys {
          id
        }
        preTitle
        title
        description
        image {
          title
          fileName
          size
          url
          contentType
        }
        link {
          sys {
            id
          }
          link
          enLink: link(locale: "en-US")
          deLink: link(locale: "${deLinkLocale}")
          label
        }
        recurrence
      }
    }
  `;
};
