import { LOCALE } from '@/store/app/models/Locale';
import { formatDate } from '@/helpers/date';
import { DKAsset } from '@/models/DKAsset';
import { generateUniqueId } from '@/helpers/has-id';
import {
  EqsNewsItem,
  EqsNewsItemAttachment,
} from '@dormakaba/search-type-definitions/dist/eqs/item';
import {
  EqsNewsLanguage,
  EqsNewsLanguages,
} from '@dormakaba/search-type-definitions/dist/eqs/languages';
import { ELocales } from '@/models/Locales';

/**
 * Convert locale to eqs news language
 * @param locale
 */
export const getEqsNewsLanguageFromLocale = (
  locale: LOCALE
): EqsNewsLanguage => {
  return locale === ELocales.DE
    ? EqsNewsLanguages.GERMAN
    : EqsNewsLanguages.ENGLISH;
};

/**
 * Convert string to slug
 * @param text
 */
export const stringToSlug = (text: string): string => {
  return text
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, '-')
    .replace(/(^-+)|(-+$)/g, '');
};

/**
 * Format published date according to locale
 * @param date
 * @param locale
 */
export const formatPublishedDate = (date: string, locale: string): string => {
  return formatDate(date, locale);
};

/**
 * Cut text to given length and add ellipsis
 * @param text
 * @param length
 * @param ellipsis
 */
export const getExcerpt = (
  text: string | undefined | null,
  length: number,
  ellipsis = '...'
): string => {
  if (text && text.length > length) {
    const content = text.substring(0, length);
    const lastSpaceIndex = content.lastIndexOf(' ');
    return `${content.substring(
      0,
      lastSpaceIndex !== -1 ? lastSpaceIndex : content.length
    )} ${ellipsis}`;
  }

  return text || '';
};

/**
 * Get header image from a news item
 * @param item
 */
export const getHeaderImageFromNewsItem = (item: EqsNewsItem): string => {
  if (!item.attachments) {
    return '';
  }

  return (
    item.attachments?.find(
      (attachment: EqsNewsItemAttachment) =>
        (attachment.mime_type.includes('image') ||
          attachment.mime_type.includes('webp')) &&
        attachment.title.toLowerCase().includes('header')
    )?.url ||
    item.attachments?.find(
      (attachment: EqsNewsItemAttachment) =>
        attachment.mime_type.includes('image') ||
        attachment.mime_type.includes('webp')
    )?.url ||
    ''
  );
};

/**
 * Get header image DKAsset from a news item
 * @param item
 */
export const getHeaderImageObjFromNewsItem = (
  item: EqsNewsItem
): DKAsset | undefined => {
  if (!item.attachments) return;

  const eqsImage =
    item.attachments?.find(
      (attachment: EqsNewsItemAttachment) =>
        (attachment.mime_type.includes('image') ||
          attachment.mime_type.includes('webp')) &&
        attachment.title.toLowerCase().includes('header')
    ) ||
    item.attachments?.find(
      (attachment: EqsNewsItemAttachment) =>
        attachment.mime_type.includes('image') ||
        attachment.mime_type.includes('webp')
    );

  if (!eqsImage) return;

  return {
    url: eqsImage.url,
    title: eqsImage.title,
    fileName: eqsImage.title,
    size: 0,
    id: generateUniqueId(),
  };
};
