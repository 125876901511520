import { Module } from 'vuex';
import { getters } from '@/store/search/getters';
import { actions } from '@/store/search/actions';
import { mutations } from '@/store/search/mutations';
import { DKUnifiedSearchResult } from '@/store/search/models/DKUnifiedSearchResult';
import { EqsNewsItem } from '@dormakaba/search-type-definitions/dist/eqs/item';

export interface SearchState {
  searchResults: {
    loading: boolean;
    hasMore: boolean;
    data: DKUnifiedSearchResult[];
  };
  newsResults: {
    loading: boolean;
    hasMore: boolean;
    data: EqsNewsItem[];
  };
}

const searchModule: Module<SearchState, any> = {
  state: () => ({
    searchResults: {
      loading: false,
      hasMore: false,
      data: [],
    },
    newsResults: {
      loading: false,
      hasMore: false,
      data: [],
    },
  }),
  getters,
  actions,
  mutations,
  namespaced: true,
};

export default searchModule;
