import { ShortySegment } from '../../../types/contentful-api';
import { GenericFactory } from '@/models/factories/GenericFactory';
import { DKAsset } from '@/models/DKAsset';
import { DKLink } from '@/models/DKLink';
import { AssetFactory } from '@/models/factories/AssetFactory';
import { LinkFactory } from '@/models/factories/LinkFactory';
import { PropertyRequiredError } from '@/models/factories/PropertyRequiredError';
import { DKShortySegment } from '@dormakaba/dormakaba-components/dist/types/lib/models/DKShortySegment';

export class ShortySegmentFactory extends GenericFactory<
  ShortySegment,
  DKShortySegment
> {
  contentType = 'ShortySegment';
  requiredProperties = ['title', 'body'];

  protected map(source: ShortySegment): DKShortySegment {
    let image: DKAsset | undefined;
    let link: DKLink | undefined;

    if (source.image) {
      const assetFactory = new AssetFactory(this.locale);
      try {
        image = assetFactory.create(source.image);
      } catch (e: any) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate Image');
          console.warn(e.message);
        } else {
          throw e;
        }
      }
    }

    if (source.link) {
      const linkFactory = new LinkFactory(this.locale);
      try {
        link = linkFactory.create(source.link);
      } catch (e: unknown) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate Image');
          console.warn(e.message);
        } else {
          throw e;
        }
      }
    }

    return Object.assign({} as DKShortySegment, { ...source, image, link });
  }
}
