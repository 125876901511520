import gql from 'graphql-tag';

export const verticalCollectionQuery = (linkLink: string) => {
  return gql`
    query ($locale: String, $preview: Boolean = false) {
      verticalCollection(locale: $locale, preview: $preview) {
        items {
          sys {
            id
          }
          slug
          title
          description {
            json
          }
          headerImage {
            fileName
            url
            title
            size
            contentType
          }
          icon
          quotesCollection(limit: 5) {
            items {
              sys {
                id
              }
              quote
              firstName
              lastName
              image {
                fileName
                url
                title
                size
                contentType
              }
              function
            }
          }
          useCasesCollection(limit: 7) {
            items {
              sys {
                id
              }
              slug
              title
              objectName
              country
              isPage
              headerImage {
                title
                fileName
                size
                url
                contentType
              }
            }
          }
          solutionsCollection(limit: 20) {
            items {
              sys {
                id
              }
              icon
              title
              description
            }
          }
          download {
            fileName
            url
            title
            size
            contentType
          }
          link (locale: "en-US") {
            node {
              __typename
              ... on ${linkLink} {
                sys {
                  id
                }
              }
            }
          }
        }
      }
    }
  `;
};
