import { PropertyRequiredError } from '@/models/factories/PropertyRequiredError';
import { LOCALE } from '@/store/app/models/Locale';

export abstract class GenericFactory<S, R extends { [key: string]: any }> {
  protected abstract contentType: string;
  protected abstract requiredProperties: string[];
  locale: LOCALE;

  constructor(locale: LOCALE) {
    this.locale = locale;
  }

  create(source: S): R {
    const result = this.map(source);
    this.validate(result);

    return result;
  }

  protected abstract map(source: S): R;

  protected validate(result: R): void {
    this.requiredProperties.forEach((property: string) => {
      // consider that property can be 0 or false which are valid values!
      if (
        !Object.prototype.hasOwnProperty.call(result, property) ||
        result[property] === undefined ||
        result[property] === null ||
        result[property] === ''
      ) {
        throw new PropertyRequiredError(this.contentType, property, result);
      }
    });
  }
}
