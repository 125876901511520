import { DirectiveBinding } from 'vue';

export default {
  beforeMount(el: any, binding: DirectiveBinding) {
    el.clickOutsideEvent = (event: MouseEvent | KeyboardEvent) => {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event);
      }
    };
    document.addEventListener('click', el.clickOutsideEvent);
    document.addEventListener('keydown', el.clickOutsideEvent);
  },
  unmounted(el: any) {
    document.removeEventListener('click', el.clickOutsideEvent);
    document.removeEventListener('keydown', el.clickOutsideEvent);
  },
};
