import {
  ContactGroupFragment,
  ContactSegment,
} from '../../../types/contentful-api';
import { GenericFactory } from '@/models/factories/GenericFactory';
import { DKContactGroupFragment } from '@/models/DKContactGroupFragment';
import { DKContactSegment } from '@/models/DKContactSegment';
import { ContactSegmentFactory } from '@/models/factories/ContactSegmentFactory';
import { PropertyRequiredError } from '@/models/factories/PropertyRequiredError';

export class ContactGroupFragmentFactory extends GenericFactory<
  ContactGroupFragment,
  DKContactGroupFragment
> {
  contentType = 'ContactGroupFragment';
  requiredProperties = ['shortTitle', 'title'];

  protected map(source: ContactGroupFragment): DKContactGroupFragment {
    const segments: DKContactSegment[] = [];
    const contactSegmentFactory = new ContactSegmentFactory(this.locale);

    if (source.segmentsCollection?.items) {
      const items: ContactSegment[] = source.segmentsCollection
        .items as ContactSegment[];
      items.forEach((contactSegment: ContactSegment) => {
        try {
          segments.push(contactSegmentFactory.create(contactSegment));
        } catch (e) {
          if (e instanceof PropertyRequiredError) {
            console.warn('Could not generate ContactSegment');
            console.warn(e.message);
          } else {
            throw e;
          }
        }
      });
    }

    return Object.assign({} as DKContactGroupFragment, {
      ...source,
      segments,
    });
  }
}
