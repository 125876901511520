import {
  Quote,
  VerticalSolutionPortfolio,
  UseCase,
  Vertical,
  Link,
} from '../../../types/contentful-api';
import { GenericFactory } from '@/models/factories/GenericFactory';
import { DKVertical } from '@/models/DKVertical';
import { DKAsset } from '@/models/DKAsset';
import { DKQuote } from '@/models/DKQuote';
import { DKUseCase } from '@/models/DKUseCase';
import { DKLink } from '@/models/DKLink';
import { AssetFactory } from '@/models/factories/AssetFactory';
import { PropertyRequiredError } from '@/models/factories/PropertyRequiredError';
import { QuoteFactory } from '@/models/factories/QuoteFactory';
import { UseCaseFactory } from '@/models/factories/UseCaseFactory';
import { DKVerticalSolutionPortfolio } from '@/models/DKVerticalSolutionPortfolio';
import { VerticalSolutionPortfolioFactory } from '@/models/factories/VerticalSolutionPortfolioFactory';
import { LinkFactory } from './LinkFactory';

export class VerticalFactory extends GenericFactory<Vertical, DKVertical> {
  contentType = 'Vertical';
  requiredProperties = [
    'slug',
    'title',
    'description',
    'headerImage',
    'icon',
    'quotes',
    'useCases',
  ];

  protected map(source: Vertical): DKVertical {
    let headerImage: DKAsset | undefined;
    let download: DKAsset | undefined;
    let metaImage: DKAsset | undefined;
    let link: DKLink | undefined;
    const quotes: DKQuote[] = [];
    const useCases: DKUseCase[] = [];
    const solutions: DKVerticalSolutionPortfolio[] = [];

    if (source.headerImage) {
      const assetFactory = new AssetFactory(this.locale);
      try {
        headerImage = assetFactory.create(source.headerImage);
      } catch (e: any) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate HeaderImage');
          console.warn(e.message);
        } else {
          throw e;
        }
      }
    }

    if (source.download) {
      const assetFactory = new AssetFactory(this.locale);
      try {
        download = assetFactory.create(source.download);
      } catch (e: any) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate Download');
          console.warn(e.message);
        } else {
          throw e;
        }
      }
    }

    if (source.metaImage) {
      const assetFactory = new AssetFactory(this.locale);
      try {
        metaImage = assetFactory.create(source.metaImage);
      } catch (e: any) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate MetaImage');
          console.warn(e.message);
        } else {
          throw e;
        }
      }
    }

    if (source.quotesCollection?.items) {
      const quoteFactory = new QuoteFactory(this.locale);
      const items: Quote[] = source.quotesCollection.items as Quote[];
      items.forEach((item: Quote) => {
        try {
          quotes.push(quoteFactory.create(item));
        } catch (e: any) {
          if (e instanceof PropertyRequiredError) {
            console.warn('Could not generate Quote');
            console.warn(e.message);
          } else {
            throw e;
          }
        }
      });
    }

    if (source.useCasesCollection?.items) {
      const useCaseFactory = new UseCaseFactory(this.locale);
      const items: UseCase[] = source.useCasesCollection.items as UseCase[];
      items.forEach((item: UseCase) => {
        try {
          useCases.push(useCaseFactory.create(item));
        } catch (e: any) {
          if (e instanceof PropertyRequiredError) {
            console.warn('Could not generate UseCase');
            console.warn(e.message);
          } else {
            throw e;
          }
        }
      });
    }

    if (source.solutionsCollection?.items) {
      const verticalSolutionPortfolioFactory =
        new VerticalSolutionPortfolioFactory(this.locale);
      const items: VerticalSolutionPortfolio[] = source.solutionsCollection
        .items as VerticalSolutionPortfolio[];
      items.forEach((item: VerticalSolutionPortfolio) => {
        try {
          solutions.push(verticalSolutionPortfolioFactory.create(item));
        } catch (e: any) {
          if (e instanceof PropertyRequiredError) {
            console.warn('Could not generate Solution');
            console.warn(e.message);
          } else {
            throw e;
          }
        }
      });
    }

    if (source.link) {
      const linkFactory = new LinkFactory(this.locale);

      try {
        link = linkFactory.create(source.link as unknown as Link);
      } catch (e) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate Link');
          console.warn(e);
        } else {
          throw e;
        }
      }
    }

    return Object.assign({} as DKVertical, {
      ...source,
      headerImage,
      link,
      download,
      metaImage,
      quotes,
      useCases,
      solutions,
    });
  }
}
