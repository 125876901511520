import { Employee, Link } from '../../../types/contentful-api';
import { GenericFactory } from '@/models/factories/GenericFactory';
import { DKEmployee } from '@/models/DKEmployee';
import { DKAsset } from '@/models/DKAsset';
import { AssetFactory } from '@/models/factories/AssetFactory';
import { PropertyRequiredError } from '@/models/factories/PropertyRequiredError';
import { LinkFactory } from '@/models/factories/LinkFactory';
import { DKLink } from '@/models/DKLink';

export class EmployeeFactory extends GenericFactory<Employee, DKEmployee> {
  contentType = 'Employee';
  requiredProperties = ['firstName', 'lastName', 'function', 'image'];

  protected map(source: Employee): DKEmployee {
    let image: DKAsset | undefined;
    let formLink: DKLink | undefined;

    if (source.image) {
      const assetFactory = new AssetFactory(this.locale);
      try {
        image = assetFactory.create(source.image);
      } catch (e) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate Image');
          console.warn(e.message);
        } else {
          throw e;
        }
      }
    }

    if (source.formLink) {
      const linkFactory = new LinkFactory(this.locale);
      try {
        const linkedFromLinks =
          source.formLink.linkedFrom?.linkCollection?.items;
        if (linkedFromLinks?.length) {
          const link = linkedFromLinks[0] as Link;
          formLink = linkFactory.create(link);
        }
      } catch (e) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate Form Page for Employee');
          console.warn(e.message);
        } else {
          throw e;
        }
      }
    }

    return Object.assign({} as DKEmployee, { ...source, image, formLink });
  }
}
