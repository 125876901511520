import { DateTime } from 'luxon';
import { Entry } from '../../types/contentful-api';

export const filterDuplicateByIdFn = <T extends Entry>(array: T[]): T[] => {
  const uniqueIds = new Set();
  return array.filter((el: T) => {
    const duplicate = uniqueIds.has(el.sys.id);
    uniqueIds.add(el.sys.id);
    return !duplicate;
  });
};

export const filterUpcomingDateFn = (a?: { date?: string }): boolean => {
  if (!a || !a.date) {
    return false;
  }
  const today = DateTime.local()
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    .minus({ millisecond: 1 });
  return DateTime.fromISO(a.date) >= today;
};
