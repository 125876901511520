import { ActionTree } from 'vuex';
import { DKSpaceOriginType } from '@/models/DKSpaceOrigin';
import { LOCALE } from '@/store/app/models/Locale';
import { SearchState } from '@/store/search/index';
import { Vue } from 'vue-facing-decorator';
import { getContentfulGraphQlClient } from '@/plugins/contentful-client';
import { getEnv } from '@/env';
import { searchEqsNews } from '@/api/algolia-api/algolia-client';
import searchQuery from '@/api/contentful-api/graphql/search.graphql';

const contentfulGraphQlClient = () =>
  Vue.prototype.$apolloProvider.defaultClient;

const contentTypes: string[] = [
  'vertical',
  'genericContentFragment',
  'portfolioFragment',
  'event',
  'generalMeeting',
  'legalPage',
  'management',
  'productCluster',
  'service',
  'vertical',
  'report',
];

export const actions: ActionTree<SearchState, any> = {
  async fetchSearch(
    { commit, dispatch },
    payload: { query: string; locale?: LOCALE; limit?: number; skip?: number }
  ) {
    const response = await getContentfulGraphQlClient(
      DKSpaceOriginType.LOCAL_SPACE
    ).getEntries({
      query: payload.query,
      'sys.id[exists]': 'true',
      'sys.contentType.sys.id[in]': contentTypes.join(','),
      limit: payload.limit,
      skip: payload.skip,
      locale: payload.locale,
    });
    const ids: string[] = response.items.map((item) => item.sys.id);
    dispatch('fetchSearchResults', { ...payload, ids });
    commit('setHasMore', {
      total: response.total,
      limit: response.limit,
      itemsLength: response.items.length,
    });
  },
  async fetchNewsSearch(
    { dispatch },
    payload: { query: string; locale?: LOCALE; limit?: number; skip?: number }
  ) {
    dispatch('fetchNewsSearchResults', { ...payload });
  },
  async fetchSearchResults(
    { commit },
    payload: {
      ids: string[];
      locale?: LOCALE;
      loadMore: boolean;
      limit?: number;
      skip?: number;
    }
  ) {
    const response = await contentfulGraphQlClient().query({
      query: searchQuery,
      variables: {
        ids: payload.ids || [],
        locale: payload.locale,
        preview: getEnv('ENABLE_PREVIEW') === 'true',
        limit: payload.limit,
        skip: payload.skip,
      },
      fetchPolicy: 'network-only',
    });

    if (payload.loadMore) {
      commit('addSearchResults', {
        searchResults: response.data,
        locale: payload.locale,
      });
    } else {
      commit('setSearchResults', {
        searchResults: response.data,
        locale: payload.locale,
      });
    }
  },

  async fetchNewsSearchResults(
    { commit, getters },
    payload: {
      query: string;
      locale?: LOCALE;
      page?: number;
      hitsPerPage?: number;
    }
  ) {
    const response = await searchEqsNews(payload.locale, payload.query, {
      queryParameters: {
        page: payload.page ?? 0,
        hitsPerPage: payload.hitsPerPage ?? 5,
      },
    });

    commit('setNewsSearchResults', {
      newsResults: response?.hits,
      locale: payload.locale,
    });

    commit('setNewsHasMore', {
      total: response.nbHits,
      limit: response.hitsPerPage,
      itemsLength: getters.newsResults?.length ?? 0,
    });
  },
};
