import { RouteLocation } from 'vue-router';
import { getEnv } from '@/env';

const baseUrl = getEnv('BASE_URL') || '';

export const getBaseUrl = (): string => {
  return baseUrl;
};

export const currentPath = ($route: RouteLocation): string => {
  return $route.fullPath || '';
};
