import { inject, provide } from 'vue';
import { createLogger, createStore, Store } from 'vuex';
import app from '@/store/app';
import content from '@/store/content';
import navigation from '@/store/navigation';
import search from '@/store/search';
import blog from '@/store/blog';
import contentfulInfo from '@/store/contentful-info';
import { getEnv } from '@/env';

const StoreSymbol = Symbol('vuex-store');

const debug = getEnv('DEBUG_MODE') === 'true';
const plugins = debug ? [createLogger({})] : [];

export function _createStore() {
  return createStore({
    plugins,
    modules: { app, content, navigation, search, blog, contentfulInfo },
  });
}

export function provideStore(store: Store<any>) {
  provide(StoreSymbol, store);
}

export default function useStore(): Store<any> {
  const store = inject(StoreSymbol) as Store<any>;
  if (!store) {
    throw Error('no store provided');
  }
  return store;
}
