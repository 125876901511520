import { GenericFactory } from '@/models/factories/GenericFactory';
import { PropertyRequiredError } from '@/models/factories/PropertyRequiredError';
import { EqsNewsAttachmentFactory } from '@/models/factories/EqsNewsAttachmentFactory';
import { EqsNewsCategoryFactory } from '@/models/factories/EqsNewsCategoryFactory';
import { DKNewsItem } from '@/models/DKNewsItem';
import { DKNewsItemCategory } from '@/models/DKNewsItemCategory';
import { DKNewsItemAttachment } from '@/models/DKNewsItemAttachment';
import {
  EqsNewsItem,
  EqsNewsItemAttachment,
  EqsNewsItemCategory,
} from '@dormakaba/search-type-definitions/dist/eqs/item';

export class EqsNewsItemFactory extends GenericFactory<
  EqsNewsItem,
  DKNewsItem
> {
  contentType = 'EqsNewsItem';
  requiredProperties = ['id', 'title', 'content_html', 'date_published'];

  protected map(source: EqsNewsItem): DKNewsItem {
    const categories: DKNewsItemCategory[] = [];
    const attachments: DKNewsItemAttachment[] = [];

    if (source._eqs?.categories) {
      const categoryFactory = new EqsNewsCategoryFactory(this.locale);
      const categoryList: EqsNewsItemCategory[] = source._eqs.categories;
      categoryList.forEach((category: EqsNewsItemCategory) => {
        try {
          categories.push(categoryFactory.create(category));
        } catch (e: any) {
          if (e instanceof PropertyRequiredError) {
            console.warn('Could not generate Categories for Eqs News Item');
            console.warn(e.message);
          } else {
            throw e;
          }
        }
      });
    }

    if (source?.attachments) {
      const attachmentFactory = new EqsNewsAttachmentFactory(this.locale);
      const attachmentList: EqsNewsItemAttachment[] = source.attachments;
      attachmentList.forEach((attachment: EqsNewsItemAttachment) => {
        try {
          attachments.push(attachmentFactory.create(attachment));
        } catch (e: any) {
          if (e instanceof PropertyRequiredError) {
            console.warn('Could not generate Attachments for Eqs News Item');
            console.warn(e.message);
          } else {
            throw e;
          }
        }
      });
    }

    return Object.assign({} as DKNewsItem, {
      ...source,
      __typename: 'News',
      categories,
      attachments,
    });
  }
}
