function buildEnvFallback() {
  const envFallback = {};

  for (const [key, value] of Object.entries(process.env)) {
    if (key.includes('VUE_APP_')) {
      const newKey = key.replace('VUE_APP_', '');
      envFallback[newKey] = value;
    }
  }
  return envFallback;
}

module.exports = buildEnvFallback();
