import { NavigationTreeItem } from '@/store/navigation/models/NavigationTreeItem';

export const searchTreeItemByPath = (
  path: string,
  navigationTreeItems: NavigationTreeItem[]
): NavigationTreeItem | null => {
  for (const navigationTreeItem of navigationTreeItems) {
    if (navigationTreeItem.path === path) {
      return navigationTreeItem;
    }
    const result = searchTreeItemByPath(path, navigationTreeItem.children);
    if (result) {
      return result;
    }
  }
  return null;
};
