import { DateTime, Settings } from 'luxon';
import { getLanguageFromLocale } from '@/helpers/i18n';
import { getEnv } from '@/env';

export function formatDate(date: string, locale: string): string {
  if (!date) {
    return '';
  }
  if (!locale) {
    locale = getLanguageFromLocale(getEnv('I18N_FALLBACK_LOCALE') as string);
  }

  try {
    Settings.throwOnInvalid = true;
    const format = locale === 'de' ? 'EEEE, d. MMMM yyyy' : 'EEEE, d MMMM yyyy';
    return DateTime.fromISO(date, { locale: locale }).toFormat(format);
  } catch (e) {
    console.error(e);
    return '';
  }
}

export const getDayFromDate = (date: Date): string =>
  date.toISOString().split('T')[0];
