import { notEmpty } from './not-empty';

interface ReferenceNode {
  sys: {
    id: string;
  };
}

export const getNodeReferenceIds = (items: ReferenceNode[]) =>
  items.map((item: any) => item?.node?.sys.id).filter(notEmpty);

export const globalLocaleMap = (locale: string | undefined) => {
  const locales = {
    'en-US': 'en',
    'de-DE': 'de',
  };
  return locales[locale || 'en-US'];
};
