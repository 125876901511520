export class PropertyRequiredError extends Error {
  constructor(
    contentType: string,
    property: string,
    public data: { [key: string]: any }
  ) {
    super(
      `Property ${property} is required for ${contentType} (id: ${data?.sys?.id})`
    );
    Object.setPrototypeOf(this, new.target.prototype);
    this.name = 'PropertyRequiredError';
  }
}
