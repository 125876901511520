import { DKSpaceOrigin, DKSpaceOriginType } from '@/models/DKSpaceOrigin';
import gql from 'graphql-tag';

export const linkQuery = (origin: DKSpaceOrigin) => {
  // Determine the correct locale for the deLink based on the origin
  const deLinkLocale =
    origin === DKSpaceOriginType.GLOBAL_SPACE ? 'de' : 'de-DE';

  return gql`
    query ($id: String!, $locale: String = "en-US", $preview: Boolean = false) {
      link(id: $id, locale: $locale, preview: $preview) {
        sys {
          id
        }
        link
        enLink: link(locale: "en-US")
        deLink: link(locale: "${deLinkLocale}")
        label
      }
    }
  `;
};
