import { RouteLocation } from 'vue-router';
import { ActionTree } from 'vuex';
import {
  LinkFilter,
  NavigationFilter,
  NavigationItemFilter,
  Navigation,
  NavigationItem,
  Link,
  Popup,
} from '../../../types/contentful-api';
import { Vue } from 'vue-facing-decorator';
import { MenuSelectType } from '@/store/app/models/MenuSelectType';
import { NavigationState } from '@/store/navigation/index';
import { LOCALE } from '@/store/app/models/Locale';
import { popupQuery } from '@/api/contentful-api/popupQuery';
import localNavigationCollection from '@/api/contentful-api/graphql/navigationCollection.graphql';
import navigationItemQuery from '@/api/contentful-api/graphql/navigationItem.graphql';
import linkCollectionQuery from '@/api/contentful-api/graphql/linkCollection.graphql';
import { localNavigationItemCollectionQuery } from '@/api/contentful-api/navigationItemCollectionQuery';
import { resourceLink } from '@/helpers/resource-resolution';
import { currentPath } from '@/helpers/currentPath';
import { isAbsoluteLink } from '@/helpers/link';
import { notEmpty } from '@/helpers/not-empty';
import { getEnv } from '@/env';
import { DKSpaceOriginType } from '@/models/DKSpaceOrigin';
import { DKSpaceSource } from '@/models/DKSpaceSource';
import { StringMap } from '@/models/StringMap';

const contentfulGraphQlClient = () =>
  Vue.prototype.$apolloProvider.defaultClient;

const globalContentfulGraphQlClient = () =>
  Vue.prototype.$apolloProvider.clients.contentfulGraphQlGlobalClient;

export async function getLocalNavigationCollection(locale: LOCALE) {
  const localWhere: NavigationFilter = {
    menuSelect_in: Object.values(MenuSelectType),
  };

  const $collectionResponse = contentfulGraphQlClient().query({
    query: localNavigationCollection,
    variables: {
      locale: locale,
      preview: getEnv('ENABLE_PREVIEW') === 'true',
      where: localWhere,
    },
  });

  const $itemsCollectionResponse = contentfulGraphQlClient().query({
    query: localNavigationItemCollectionQuery(resourceLink('Popup')),
    variables: {
      locale: locale,
      preview: getEnv('ENABLE_PREVIEW') === 'true',
    },
  });

  const [collectionResponse, itemsCollectionResponse] = await Promise.all([
    $collectionResponse,
    $itemsCollectionResponse,
  ]);

  const localNavigations: Navigation[] =
    collectionResponse.data?.navigationCollection?.items || [];

  const localNavigationItems: NavigationItem[] =
    itemsCollectionResponse.data?.navigationItemCollection?.items || [];

  return { localNavigations, localNavigationItems };
}

const linkNotEmpty = (item: NavigationItem) => notEmpty(item.link);
const removeLocalizedLinkProperties = (item: NavigationItem) => {
  return {
    ...item,
    link: {
      sys: {
        id: item.link?.sys.id,
      },
      link: item.link?.link,
      page: item.link?.page,
    },
  };
};
const shouldBeLoadedFromGlobal = (link: Link) =>
  link.link && !link.page && !isAbsoluteLink(link.link) && link.sys.id;
const getUniqueLinks = (links1: Link[], links2: Link[]): Link[] => {
  return [
    ...new Map(
      [...links1, ...links2].map((item) => [item['link'], item])
    ).values(),
  ];
};

export const actions: ActionTree<NavigationState, any> = {
  async fetchNavigationItemBySlug(
    { commit },
    payload: { path: string; locale?: LOCALE }
  ) {
    const where: NavigationItemFilter = {
      link: {
        link: payload.path,
      },
    };
    const response = await contentfulGraphQlClient().query({
      query: navigationItemQuery,
      variables: {
        locale: payload.locale || 'en-US',
        preview: getEnv('ENABLE_PREVIEW') === 'true',
        where,
      },
    });
    commit('setNavigationItem', response.data.navigationItems.items[0]);
  },
  async fetchNavigationItems(
    { commit, dispatch },
    payload: {
      locale: LOCALE;
      $route: RouteLocation;
    }
  ) {
    const locale = payload.locale;
    const { localNavigations, localNavigationItems } =
      await getLocalNavigationCollection(locale);

    const links: DKSpaceSource<Link>[] = [];
    const linksToLoadFromGlobal: StringMap = {};

    const nonEmptyNavigationItems = localNavigationItems.filter(linkNotEmpty);
    const localNavigationItemsWithLinkRefs = nonEmptyNavigationItems.map(
      removeLocalizedLinkProperties
    );

    // We add the navigation items from Country
    nonEmptyNavigationItems
      .map((item: NavigationItem) => item.link as Link)
      .forEach((link: Link) => {
        if (shouldBeLoadedFromGlobal(link)) {
          linksToLoadFromGlobal[link.sys.id] = link.link as string;
        }

        links.push({
          ...link,
          origin: DKSpaceOriginType.LOCAL_SPACE,
        });
      });

    // We now load the links from Global which were defined locally but were not having a page
    if (Object.keys(linksToLoadFromGlobal).length) {
      const fallbackLinksFilter: LinkFilter = {
        link_in: Object.values(linksToLoadFromGlobal),
      };
      const $globalFallbackResponse = globalContentfulGraphQlClient().query({
        query: linkCollectionQuery,
        variables: {
          // Deutsch uses 'de' instead of 'de-DE' as locale at global
          locale: locale === 'de-DE' ? 'de' : locale,
          preview: getEnv('ENABLE_PREVIEW') === 'true',
          where: fallbackLinksFilter,
        },
      });
      // load global fallback locale pages as well
      const $globalFallbackWithFallbackLocaleResponse =
        globalContentfulGraphQlClient().query({
          query: linkCollectionQuery,
          variables: {
            locale: getEnv('I18N_GLOBAL_FALLBACK_LOCALE'),
            preview: getEnv('ENABLE_PREVIEW') === 'true',
            where: fallbackLinksFilter,
          },
        });

      // fetch all global links async
      const [globalFallbackResponse, globalFallbackWithFallbackLocaleResponse] =
        await Promise.all([
          $globalFallbackResponse,
          $globalFallbackWithFallbackLocaleResponse,
        ]);

      const globalFallbackItems =
        globalFallbackResponse.data.linkCollection.items.filter(linkNotEmpty);

      const globalFallbackWithFallbackLocaleItems =
        globalFallbackWithFallbackLocaleResponse.data.linkCollection.items.filter(
          linkNotEmpty
        );

      const globalFallbackLinks = getUniqueLinks(
        globalFallbackItems,
        globalFallbackWithFallbackLocaleItems
      );

      const updatePageAndOriginOfLocalLink = (link: Link) => {
        const localLinkId = Object.keys(linksToLoadFromGlobal).find(
          (key) => linksToLoadFromGlobal[key] === link.link
        );
        const localLink = links.find(
          (item: Link) => item.sys.id === localLinkId
        );

        if (localLink) {
          localLink.page = link.page;
          localLink.origin = DKSpaceOriginType.GLOBAL_SPACE;
        }
      };

      globalFallbackLinks.forEach(updatePageAndOriginOfLocalLink);
    }

    /*** POPUP */
    const popupsPromise: Promise<Popup>[] = [];
    const popupsOptions = (navigationItem: NavigationItem): void => {
      const { popup: globalPopup, localPopup } = navigationItem;
      const options = {
        localPopup: {
          locale,
          id: localPopup?.sys.id,
          origin: DKSpaceOriginType.LOCAL_SPACE,
        },
        globalPopup: {
          // Deutsch uses 'de' instead of 'de-DE' as locale at global
          locale: locale === 'de-DE' ? 'de' : locale,
          id: globalPopup?.node?.sys.id,
          origin: DKSpaceOriginType.GLOBAL_SPACE,
        },
      };

      const popup = globalPopup?.node
        ? options.globalPopup
        : options.localPopup;
      popupsPromise.push(dispatch('fetchPopupAction', popup));
    };

    for (const item of localNavigationItemsWithLinkRefs) {
      if (item.popup?.node || item.localPopup) {
        popupsOptions(item as NavigationItem);
      }
    }
    await Promise.all(popupsPromise);
    /*** ----- */

    commit('setNavigations', localNavigations);
    commit(
      'setNavigationItems',
      localNavigationItemsWithLinkRefs as NavigationItem[]
    );

    commit('setLinks', links);
    commit('setCurrentLinkByPath', currentPath(payload.$route));
    commit('setReady');
  },
  async fetchLinkBySlug(
    { commit },
    payload: { path: string; locale?: LOCALE }
  ) {
    const where: LinkFilter = {
      link: `/${payload.path}`,
    };
    const response = await contentfulGraphQlClient().query({
      query: linkCollectionQuery,
      variables: {
        locale: payload.locale || 'en-US',
        preview: getEnv('ENABLE_PREVIEW') === 'true',
        where,
      },
    });
    commit('setLink', response.data.linkCollection.items[0]);
  },
  async fetchPopupAction(
    { commit },
    payload: { id: string; origin: DKSpaceOriginType; locale: LOCALE }
  ) {
    let _contentfulGraphQlClient = contentfulGraphQlClient;
    let locale = payload.locale;

    if (payload.origin === DKSpaceOriginType.GLOBAL_SPACE) {
      _contentfulGraphQlClient = globalContentfulGraphQlClient;
      if (payload.locale === 'de-DE') {
        // Deutsch uses 'de' instead of 'de-DE' as locale at global
        locale = 'de';
      }
    }

    const response = await _contentfulGraphQlClient().query({
      query: popupQuery(payload.origin),
      variables: {
        id: payload.id,
        locale,
        preview: getEnv('ENABLE_PREVIEW') === 'true',
      },
    });

    const { popup } = response.data;
    if (!popup) return;

    commit('setPopupsCollection', {
      id: payload.id,
      data: popup,
    });
  },
};
