import { Fact, FactListFragment } from '../../../types/contentful-api';
import { GenericFactory } from '@/models/factories/GenericFactory';
import { PropertyRequiredError } from '@/models/factories/PropertyRequiredError';
import { DKFactListFragment } from '@/models/DKFactListFragment';
import { DKFact } from '@/models/DKFact';
import { FactFactory } from '@/models/factories/FactFactory';

export class FactListFragmentFactory extends GenericFactory<
  FactListFragment,
  DKFactListFragment
> {
  contentType = 'FactListFragment';
  requiredProperties = ['shortTitle'];

  protected map(source: FactListFragment): DKFactListFragment {
    const facts: DKFact[] = [];
    const factFactory = new FactFactory(this.locale);

    if (source.factsCollection?.items) {
      const items: Fact[] = source.factsCollection.items as Fact[];
      items.forEach((fact: Fact) => {
        try {
          facts.push(factFactory.create(fact));
        } catch (e) {
          if (e instanceof PropertyRequiredError) {
            console.warn('Could not generate Fact');
            console.warn(e.message);
          } else {
            throw e;
          }
        }
      });
    }

    return Object.assign({} as DKFactListFragment, { ...source, facts });
  }
}
