import {
  GenericContentFragment,
  GenericPage,
  Link,
  PortfolioFragment,
} from '../../types/contentful-api';

import { DKLink } from '@/models/DKLink';
import { DKSearchResults } from '@/store/search/models/DKSearchResults';
import { DKUnifiedSearchResult } from '@/store/search/models/DKUnifiedSearchResult';
import { LOCALE } from '@/store/app/models/Locale';
import { LinkFactory } from '@/models/factories/LinkFactory';
import { PropertyRequiredError } from '@/models/factories/PropertyRequiredError';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { formatHash } from '@/helpers/format-hash';
import { getLanguageFromLocale } from '@/helpers/i18n';
import { notEmpty } from '@/helpers/not-empty';

export function unifySearchResults(
  payload: DKSearchResults,
  locale: LOCALE
): DKUnifiedSearchResult[] {
  const unifiedSearchResults: DKUnifiedSearchResult[] = [];

  /** Events */
  payload.eventCollection.items.filter(notEmpty).forEach((item) => {
    payload.eventMainPageLinkCollection.items
      .filter(notEmpty)
      .forEach((eventMainPageLinkItem) => {
        const link = getLink(eventMainPageLinkItem, locale);
        unifiedSearchResults.push({
          id: item.sys.id,
          title: item.title,
          description: item.description,
          link: link?.link || '/',
          __typename: 'EventCollection',
        });
      });
  });

  /** General Meetings */
  payload.generalMeetingCollection.items.filter(notEmpty).forEach((item) => {
    payload.generalMeetingFragmentCollection.items
      .filter(notEmpty)
      .forEach((generalMeetingFragment) => {
        const genericPageItems = getGenericPageItems(generalMeetingFragment);
        genericPageItems.forEach((genericPageItem) => {
          const link = getLink(genericPageItem, locale);
          const slug = formatHash(generalMeetingFragment.shortTitle || '');
          unifiedSearchResults.push({
            id: item.sys.id,
            title: item.title,
            description: getContent(item.description?.json),
            link: link ? `${link.link}#${slug}` : '/',
            __typename: 'GeneralMeetingCollection',
          });
        });
      });
  });

  /** Generic Content Fragment */
  payload.genericContentFragmentCollection.items
    .filter(notEmpty)
    .forEach((item) => {
      const genericPageItems = getGenericPageItems(item);
      genericPageItems.filter(notEmpty).forEach((genericPageItem) => {
        const link = getLink(genericPageItem, locale);
        const slug = formatHash(item.shortTitle || '');
        const contentFragment = getContentFragment(
          genericPageItem.contentFragmentsCollection?.items.filter(
            notEmpty
          ) as GenericContentFragment[],
          slug
        );
        unifiedSearchResults.push({
          id: item.sys.id,
          title: genericPageItem.title,
          description: getContent(contentFragment?.body?.json),
          link: link ? `${link.link}#${slug}` : '/',
          __typename: 'GenericContentFragmentCollection',
        });
      });
    });

  /** Generic Page */
  payload.genericPageCollection.items.filter(notEmpty).forEach((item) => {
    const link = getLink(item, locale);
    unifiedSearchResults.push({
      id: item.sys.id,
      title: item.title,
      description: item.teaserText,
      link: link?.link || '/',
      __typename: 'GenericPageCollection',
    });
  });

  /** Legal Page */
  payload.legalPageCollection.items.filter(notEmpty).forEach((item) => {
    const lang: string = getLanguageFromLocale(locale);
    unifiedSearchResults.push({
      id: item.sys.id,
      title: item.title,
      description: getContent(item.body?.json),
      link: `/${lang}/${item.slug || ''}`,
      __typename: 'LegalPageCollection',
    });
  });

  /** Management */
  payload.managementCollection.items.filter(notEmpty).forEach((item) => {
    const lang: string = getLanguageFromLocale(locale);
    unifiedSearchResults.push({
      id: item.sys.id,
      title: `${item.firstName} ${item.lastName}`,
      description: item.function,
      link: `/${lang}/corporate-governance/${item.slug}`,
      __typename: 'ManagementCollection',
    });
  });

  /** Portfolio Fragment */
  payload.portfolioFragmentCollection.items.filter(notEmpty).forEach((item) => {
    const genericPageItems = getGenericPageItems(item);
    genericPageItems.filter(notEmpty).forEach((genericPageItem) => {
      const link = getLink(genericPageItem, locale);
      const slug = formatHash(item.shortTitle || '');
      unifiedSearchResults.push({
        id: item.sys.id,
        title: genericPageItem.title,
        description: genericPageItem.teaserText,
        link: `${link?.link || ''}#${slug}`,
        __typename: 'PortfolioFragmentCollection',
      });
    });
  });

  /** Product Cluster */
  payload.productClusterCollection.items.filter(notEmpty).forEach((item) => {
    const portfolioFragmentItems = getPortfolioFragmentItems(item);
    portfolioFragmentItems.forEach((portfolioFragmentItem) => {
      const genericPageItems = getGenericPageItems(portfolioFragmentItem);
      genericPageItems.forEach((genericPageItem) => {
        const link = getLink(genericPageItem, locale);
        unifiedSearchResults.push({
          id: item.sys.id,
          title: genericPageItem.title,
          description: genericPageItem.teaserText,
          link: `${link?.link || ''}#${item.slug}`,
          __typename: 'ProductClusterCollection',
        });
      });
    });
  });

  /** Report */
  payload.reportCollection.items.filter(notEmpty).forEach((item) => {
    unifiedSearchResults.push({
      id: item.sys.id,
      title: item.title,
      description: '',
      link: item.report?.url || '',
      __typename: 'ReportCollection',
    });
  });

  /** Service */
  payload.serviceCollection.items.filter(notEmpty).forEach((item) => {
    const portfolioFragmentItems = getPortfolioFragmentItems(item);
    portfolioFragmentItems.forEach((portfolioFragmentItem) => {
      const genericPageItems = getGenericPageItems(portfolioFragmentItem);
      genericPageItems.forEach((genericPageItem) => {
        const link = getLink(genericPageItem, locale);
        unifiedSearchResults.push({
          id: item.sys.id,
          title: genericPageItem.title,
          description: genericPageItem.teaserText,
          link: `${link?.link || ''}#${item.slug}`,
          __typename: 'ServiceCollection',
        });
      });
    });
  });

  /** Verticals -> City page */
  const cityLinkCollection = payload.cityMainPageLinkCollection.items[0];
  const cityLink = getLink(cityLinkCollection, locale);
  if (cityLink) {
    payload.verticalCollection.items.filter(notEmpty).forEach((vertical) => {
      unifiedSearchResults.push({
        id: vertical.sys.id,
        title: vertical.title,
        link: cityLink.link,
        __typename: 'VerticalCollection',
      });
    });
  }

  return unifiedSearchResults;
}

function getPortfolioFragmentItems(item: any): PortfolioFragment[] {
  if (item.linkedFrom?.portfolioFragmentCollection) {
    return item.linkedFrom.portfolioFragmentCollection.items.filter(notEmpty);
  }
  return [];
}

function getGenericPageItems(item: any): GenericPage[] {
  if (item.linkedFrom?.genericPageCollection) {
    return item.linkedFrom.genericPageCollection.items.filter(notEmpty);
  }
  return [];
}

function getLink(item: any, locale: LOCALE): DKLink | undefined {
  if (item.linkedFrom?.linkCollection?.items?.length) {
    const link = item.linkedFrom.linkCollection.items[0] as Link;
    const linkFactory = new LinkFactory(locale);

    try {
      return linkFactory.create(link);
    } catch (e) {
      if (e instanceof PropertyRequiredError) {
        console.warn('Could not generate Link');
        console.warn(e);
      } else {
        throw e;
      }
    }
  }
  return undefined;
}

function getContentFragment(
  items: GenericContentFragment[] | undefined,
  slug: string
): GenericContentFragment | undefined {
  return (
    items && items.find((item) => formatHash(item.shortTitle || '') === slug)
  );
}

function getContent(json: any): string {
  return documentToPlainTextString(json);
}
