import { GenericFactory } from '@/models/factories/GenericFactory';
import { PropertyRequiredError } from '@/models/factories/PropertyRequiredError';
import { EqsNewsItemFactory } from '@/models/factories/EqsNewsItemFactory';
import { DKNewsResponse } from '@/models/DKNewsResponse';
import { DKNewsItem } from '@/models/DKNewsItem';
import { DKNewsPagination } from '@/models/DKNewsPagination';
import { EqsNewsItem } from '@dormakaba/search-type-definitions/dist/eqs/item';

export class EqsNewsResponseFactory extends GenericFactory<
  EqsNewsItem[],
  DKNewsResponse
> {
  contentType = 'EqsNewsResponse';
  requiredProperties = [];

  protected map(source: EqsNewsItem[]): DKNewsResponse {
    const items: DKNewsItem[] = [];
    let pagination: DKNewsPagination | undefined;

    if (source) {
      const newsItemFactory = new EqsNewsItemFactory(this.locale);
      const newsItemsList: EqsNewsItem[] = source;
      newsItemsList.forEach((item: EqsNewsItem) => {
        try {
          items.push(newsItemFactory.create(item));
        } catch (e: any) {
          if (e instanceof PropertyRequiredError) {
            console.warn('Could not generate NewsItems for EqsResponse');
            console.warn(e.message);
          } else {
            throw e;
          }
        }
      });
    }

    return Object.assign({} as DKNewsResponse, {
      ...source,
      items,
      pagination,
    });
  }
}
