import { GenericAccordionElement } from '../../../types/contentful-api';
import { GenericFactory } from '@/models/factories/GenericFactory';
import { DKGenericAccordionElement } from '../DKGenericAccordionElement';

export class GenericAccordionElementFactory extends GenericFactory<
  GenericAccordionElement,
  DKGenericAccordionElement
> {
  contentType = 'GeneralAccordionElement';
  requiredProperties = ['title'];

  protected map(source: GenericAccordionElement): DKGenericAccordionElement {
    return Object.assign({} as DKGenericAccordionElement, source);
  }
}
