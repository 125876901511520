import { MutationTree } from 'vuex';
import { AppState } from '@/store/app';
import { LOCALE } from '@/store/app/models/Locale';

export const mutations: MutationTree<AppState> = {
  toggleMenu: (state: AppState) => {
    state.ui.isMenuOpen = !state.ui.isMenuOpen;
  },
  openMenu: (state: AppState) => {
    state.ui.isMenuOpen = true;
  },
  closeMenu: (state: AppState) => {
    state.ui.isMenuOpen = false;
  },
  toggleBackdrop: (state: AppState) => {
    state.ui.hasBackdrop = !state.ui.hasBackdrop;
  },
  openBackdrop: (state: AppState) => {
    state.ui.hasBackdrop = true;
  },
  closeBackdrop: (state: AppState) => {
    state.ui.hasBackdrop = false;
  },
  setHeaderType: (state: AppState, payload) => {
    state.ui.headerType = payload;
  },
  setFooterGroup: (state: AppState, payload: boolean) => {
    state.ui.showFooterGroup = payload;
  },
  clearFooterGroup: (state: AppState) => {
    state.ui.showFooterGroup = true;
  },
  setLocale: (state: AppState, payload: { locale: LOCALE }) => {
    state.ui.locale = payload.locale;
  },
  setVerticalOverview: (state: AppState, payload) => {
    state.verticalOverview = payload.items[0] || null;
  },
  setStockPrice: (state: AppState, payload) => {
    state.footer.stockPrice = payload;
  },
};
