<script setup lang="ts">
import {
  computed,
  ref,
  toRefs,
  PropType,
  onMounted,
  onBeforeUnmount,
  watch,
  ComponentPublicInstance,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useMq } from 'vue3-mq';
import { useRoute } from 'vue-router';

import { DKButton, DKIcon } from '@dormakaba/dormakaba-components';
import { isMobile as $isMobile } from '@dormakaba/dormakaba-components';

const emit = defineEmits<{
  search: [string];
  clickSearchSuggestion: [string];
  remove: [];
}>();

const props = defineProps({
  id: {
    type: String,
    default: 'search',
  },
  label: {
    type: String,
    default: 'Search',
  },
  searchTerm: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    default: 'primary',
  },
  isFluid: {
    type: Boolean,
    default: false,
  },
  isFooter: {
    type: Boolean,
    default: false,
  },
  isInline: {
    type: Boolean,
    default: false,
  },
  isHamburger: {
    type: Boolean,
    default: false,
  },
  autofocus: {
    type: Boolean,
    default: false,
  },
  autocomplete: {
    type: Boolean,
    default: false,
  },
  searchSuggestions: {
    type: Array as PropType<string[]>,
    default: () => [],
  },
});

const { t } = useI18n();
const mq = useMq();

const route = useRoute();

const suggestionsSliderIntervalDuration = 4000;
const suggestionTypeTimeoutDurationEnd = 250;
const suggestionTypeTimeoutDurationMax = 100;
const suggestionTypeTimeoutDurationMin = 50;

const { searchTerm, searchSuggestions } = toRefs(props);

const isGroupAnimated = ref<boolean>(false);
const isSearchChipHidden = ref<boolean>(false);
const isChipButton = ref<boolean>(false);
const removeChipAfterAnimationOut = ref<boolean>(false);
const isPlaceholderHidden = ref<boolean>(false);
const isChipAnimatedOutFromInput = ref<boolean>(false);
const isSuggestionChipAnimatedOut = ref<boolean>(false);
const isMobileViewport = ref<boolean>(false);

const searchTermValue = ref<string>('');
const previousSearchTerm = ref<string>('');
const suggestionTerm = ref<string>('');
const currentSuggestionTerm = ref<string>('');

const currentSuggestionTermCharIndex = ref<number>(0);

const searchChipButton = ref<ComponentPublicInstance<HTMLButtonElement> | null>(
  null
);
const searchGroupAnimation =
  ref<ComponentPublicInstance<HTMLButtonElement> | null>(null);
const suggestionChipButton =
  ref<ComponentPublicInstance<HTMLButtonElement> | null>(null);
const previousChipButton =
  ref<ComponentPublicInstance<HTMLButtonElement> | null>(null);
const inputRef = ref<HTMLInputElement | null>(null);

const suggestionsSliderInterval = ref<ReturnType<typeof setInterval>>();
const suggestionTypeTimeout = ref<ReturnType<typeof setInterval>>();

const isMobile = computed<boolean>(() => $isMobile(mq));
const labelIconSize = computed<number>(() => (isMobile.value ? 24 : 32));
const labelText = computed<string>(
  () => props.label ?? t('search.input.label')
);
const placeholderText = computed<string>(() =>
  !searchTerm.value ? props.placeholder : ''
);

onMounted(() => {
  initSearchChip();
  setSearchVariant();

  setSuggestions();
});

onBeforeUnmount(() => {
  stopSuggestionsSlider();
});

const onSubmit = (e: Event) => {
  if (isGroupAnimated.value) {
    e.preventDefault();
    return;
  }

  if (searchTermValue.value) {
    emit('search', searchTermValue.value);
    searchTermValue.value = '';
  } else if (searchTerm.value) {
    emit('search', searchTerm.value);
  }

  setChipAsButton();
};

const setChipAsButton = () => {
  isSearchChipHidden.value = false;
  isChipButton.value = false;

  setTimeout(() => {
    isChipButton.value = true;
  }, 10);

  if (searchChipButton.value || suggestionChipButton.value) {
    if (searchChipButton.value) {
      previousSearchTerm.value = searchTerm.value;
    } else if (suggestionChipButton.value) {
      previousSearchTerm.value = suggestionTerm.value;
    }

    setTimeout(() => {
      animateChipOut(previousChipButton.value?.$el);
    }, 10);
  }
};

const onClickSearchSuggestion = () => {
  emit('clickSearchSuggestion', suggestionTerm.value);
};

const onRemove = () => {
  if (!props.isHamburger) {
    removeChipAfterAnimationOut.value = true;
    isPlaceholderHidden.value = true;
  }

  animateChipOut(searchChipButton.value?.$el);
};

const animateChipOut = ($chipButton: HTMLElement) => {
  isGroupAnimated.value = true;

  window.requestAnimationFrame(() => {
    searchGroupAnimation.value?.style.setProperty(
      '--t-x',
      getSearchTermWidth($chipButton) + 'px'
    );

    searchGroupAnimation.value?.addEventListener(
      'transitionend',
      animateChipOutEndListener
    );
  });
};

const getSearchTermWidth = ($chipButton: HTMLElement): number => {
  const marginRight = parseInt(
    window.getComputedStyle($chipButton).marginRight.split('px')[0]
  );

  const { width } = $chipButton.getBoundingClientRect();

  return (marginRight + width) * -1;
};

const animateChipOutEndListener = (e: Event) => {
  if (e.target !== searchGroupAnimation.value) {
    return;
  }

  searchGroupAnimation.value?.removeEventListener(
    'transitionend',
    animateChipOutEndListener
  );

  previousSearchTerm.value = '';
  isGroupAnimated.value = false;
  isPlaceholderHidden.value = false;

  window.requestAnimationFrame(() => {
    searchGroupAnimation.value?.style.setProperty('--t-x', '0px');
  });

  if (props.isHamburger) {
    isSearchChipHidden.value = true;
    inputRef.value?.focus();
  }

  if (isChipAnimatedOutFromInput.value) {
    isChipAnimatedOutFromInput.value = false;
    isSearchChipHidden.value = true;
  }

  if (isSuggestionChipAnimatedOut.value) {
    isSuggestionChipAnimatedOut.value = false;
    suggestionTerm.value = '';
  }

  if (removeChipAfterAnimationOut.value) {
    removeChipAfterAnimationOut.value = false;
    processRemove();
  }
};

const setSuggestions = () => {
  if (searchSuggestions.value.length && !searchTermValue.value) {
    animateSuggestion();
    launchSuggestionsSlider();
  }
};

const processRemove = () => {
  emit('remove');
  inputRef.value?.focus();
};

const animateSuggestion = () => {
  setCurrentSuggestion();
  typeSuggestion();
};

const launchSuggestionsSlider = () => {
  reinitInterval(suggestionsSliderInterval.value);

  suggestionsSliderInterval.value = setInterval(
    animateSuggestion,
    suggestionsSliderIntervalDuration
  );
};

const setCurrentSuggestion = () => {
  if (currentSuggestionTerm.value === '') {
    currentSuggestionTerm.value = searchSuggestions.value[0];
  } else {
    const currentIndex = searchSuggestions.value.indexOf(
      currentSuggestionTerm.value
    );

    const nextIndex =
      currentIndex >= searchSuggestions.value.length - 1 ? 0 : currentIndex + 1;

    currentSuggestionTerm.value = searchSuggestions.value[nextIndex];
  }
};

const typeSuggestion = () => {
  searchTermValue.value = currentSuggestionTerm.value.substring(
    0,
    currentSuggestionTermCharIndex.value
  );

  reinitTimeout(suggestionTypeTimeout.value);

  if (
    currentSuggestionTermCharIndex.value <= currentSuggestionTerm.value.length
  ) {
    currentSuggestionTermCharIndex.value++;
    suggestionTypeTimeout.value = setTimeout(
      typeSuggestion,
      getTypingDuration()
    );
  } else {
    currentSuggestionTermCharIndex.value = 0;
    suggestionTypeTimeout.value = setTimeout(
      enterSuggestion,
      suggestionTypeTimeoutDurationEnd
    );
  }

  if (
    isMobileViewport.value &&
    suggestionChipButton.value &&
    !isSuggestionChipAnimatedOut.value
  ) {
    animateChipOut(suggestionChipButton.value?.$el);
    isSuggestionChipAnimatedOut.value = true;
  }
};

const enterSuggestion = () => {
  setChipAsButton();
  suggestionTerm.value = searchTermValue.value;
  isPlaceholderHidden.value = true;

  searchTermValue.value = '';

  if (!suggestionChipButton.value) {
    setTimeout(() => {
      isPlaceholderHidden.value = false;
    }, 10);
  }
};

const getTypingDuration = (): number =>
  Math.floor(
    Math.random() *
      (suggestionTypeTimeoutDurationMax - suggestionTypeTimeoutDurationMin)
  ) + suggestionTypeTimeoutDurationMin;

const setSearchVariant = () => {
  isMobileViewport.value = mq.current === 'sm';
};

const initSearchChip = () => {
  isChipButton.value = route.query.q !== undefined;
};

const stopSuggestionsSlider = () => {
  reinitInterval(suggestionsSliderInterval.value);
  reinitTimeout(suggestionTypeTimeout.value);

  suggestionsSliderInterval.value = undefined;
  suggestionTypeTimeout.value = undefined;

  searchTermValue.value = '';

  if (suggestionChipButton.value) {
    animateChipOut(suggestionChipButton.value?.$el);
    isSuggestionChipAnimatedOut.value = true;
  }
};

const onFocusIn = () => {
  if (suggestionsSliderInterval.value || suggestionTypeTimeout.value) {
    stopSuggestionsSlider();
  }
};

const onFocusOut = () => {
  setSuggestions();
};

const onInput = () => {
  if (isMobileViewport.value && searchChipButton.value) {
    animateChipOut(searchChipButton.value?.$el);
    isChipAnimatedOutFromInput.value = true;
  }
};

const reinitInterval = (ref?: ReturnType<typeof setInterval>) => {
  if (ref) {
    clearInterval(ref);
  }
};

const reinitTimeout = (ref?: ReturnType<typeof setTimeout>) => {
  if (ref) {
    clearTimeout(ref);
  }
};

watch(() => mq.current, setSearchVariant);
</script>

<template>
  <div
    :class="[
      'dk-search',
      {
        'dk-search--primary': type === 'primary',
        'dk-search--secondary': type === 'secondary',
        'dk-search--fluid': isFluid,
      },
    ]"
  >
    <form class="dk-search__form" v-on:submit.prevent="onSubmit">
      <div
        :class="[
          'dk-search__inner',
          {
            'dk-search__inner--footer': isFooter,
          },
        ]"
      >
        <label
          :id="`${id}-label`"
          :for="id"
          class="dk-search__label"
          :class="{ 'mr-0': isInline }"
        >
          <span class="sr-only">{{ labelText }}</span>
          <div v-if="!isInline" class="dk-search__label-icon">
            <DKIcon icon="search-bold" :size="labelIconSize" />
          </div>
        </label>

        <div class="dk-search-group">
          <div class="dk-search-group__animation-mask">
            <div
              class="dk-search-group__animation"
              ref="searchGroupAnimation"
              :class="{ 'is-animated': isGroupAnimated }"
            >
              <div class="dk-search-group__prepend">
                <DKButton
                  v-if="previousSearchTerm"
                  :label="previousSearchTerm"
                  class="dk-search__chip is-button"
                  ref="previousChipButton"
                >
                  <template #icon-right>
                    <span class="dk-search__chip-icon">
                      <DKIcon
                        v-if="!suggestionTerm"
                        icon="close-bold"
                        :size="20"
                      ></DKIcon>
                    </span>
                  </template>
                </DKButton>
                <DKButton
                  v-if="suggestionTerm"
                  class="dk-search__chip dk-search__chip--suggestion"
                  :label="suggestionTerm"
                  :class="{ 'is-button': isChipButton }"
                  ref="suggestionChipButton"
                  @click="onClickSearchSuggestion"
                >
                </DKButton>
                <DKButton
                  v-if="searchTerm && !isSearchChipHidden"
                  class="dk-search__chip"
                  :label="searchTerm"
                  :class="{
                    'is-button': isChipButton,
                    'dk-button--secondary': isMobile,
                  }"
                  ref="searchChipButton"
                  @click="onRemove"
                >
                  <template #icon-right>
                    <span class="dk-search__chip-icon">
                      <DKIcon icon="close-bold" :size="20"></DKIcon>
                    </span>
                  </template>
                </DKButton>
              </div>
              <input
                :id="id"
                ref="inputRef"
                type="text"
                class="dk-search__input"
                :class="{ 'is-hidden': isPlaceholderHidden }"
                :name="id"
                :aria-label="`${id}-label`"
                :placeholder="placeholderText"
                :autofocus="autofocus"
                :autocomplete="autocomplete ? 'on' : 'off'"
                :spellcheck="false"
                v-model="searchTermValue"
                @focusin="onFocusIn"
                @focusout="onFocusOut"
                @input="onInput"
              />
            </div>
          </div>
          <div class="dk-search-group__append" v-if="isInline">
            <DKIcon icon="search-bold" :size="20"></DKIcon>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
