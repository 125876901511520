<template>
  <div class="contact-footer">
    <p class="contact-footer__title">
      {{ $t('footer.contact.title') }}
    </p>
    <ContactButtonList
      :contactNavigationTree="contactFooterNavigationTree"
      :isInverted="true"
      @clickContactLink="$emit('clickContactLink', $event)"
    ></ContactButtonList>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-facing-decorator';
import { NavigationTree } from '@/store/navigation/models/NavigationTree';
import { DKButton } from '@dormakaba/dormakaba-components';
import ContactButtonList from '@/components/ContactButtonList.vue';

@Component({
  components: {
    DKButton,
    ContactButtonList,
  },
  emits: ['clickContactLink'],
})
export default class ContactFooter extends Vue {
  @Prop() contactFooterNavigationTree!: NavigationTree;
}
</script>

<style lang="postcss" scoped>
.contact-footer {
  @apply flex flex-col;
  @apply gap-y-2;

  @apply md:gap-y-6;
}

.contact-footer__title {
  @apply heading-4;
  @apply md:heading-6;
  @apply lg:heading-5;
  @apply text-white;
}
</style>
