import {
  Management,
  ManagementListFragment,
} from '../../../types/contentful-api';
import { GenericFactory } from '@/models/factories/GenericFactory';
import { DKManagementListFragment } from '@/models/DKManagementListFragment';
import { DKManagement } from '@/models/DKManagement';
import { ManagementFactory } from '@/models/factories/ManagementFactory';
import { PropertyRequiredError } from '@/models/factories/PropertyRequiredError';

export class ManagementListFragmentFactory extends GenericFactory<
  ManagementListFragment,
  DKManagementListFragment
> {
  contentType = 'ManagementListFragment';
  requiredProperties = ['shortTitle', 'title'];

  protected map(source: ManagementListFragment): DKManagementListFragment {
    const management: DKManagement[] = [];

    if (source.managementCollection?.items) {
      const items: Management[] = source.managementCollection
        .items as Management[];
      const managementFactory = new ManagementFactory(this.locale);

      items.forEach((manager: Management) => {
        try {
          management.push(managementFactory.create(manager));
        } catch (e) {
          if (e instanceof PropertyRequiredError) {
            console.warn('Could not generate Management');
            console.warn(e);
          } else {
            throw e;
          }
        }
      });
    }

    return Object.assign({} as DKManagementListFragment, {
      ...source,
      management,
    });
  }
}
