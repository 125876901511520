import { StockChartFragment } from '../../../types/contentful-api';
import { GenericFactory } from '@/models/factories/GenericFactory';
import { DKStockChartFragment } from '@/models/DKStockChartFragment';

export class StockChartFragmentFactory extends GenericFactory<
  StockChartFragment,
  DKStockChartFragment
> {
  contentType = 'StockChartFragment';
  requiredProperties = ['shortTitle'];

  protected map(source: StockChartFragment): DKStockChartFragment {
    return Object.assign({} as DKStockChartFragment, source);
  }
}
