import {
  ProductDeclaration,
  ProductDeclarationSegment,
} from '../../../types/contentful-api';
import { GenericFactory } from '@/models/factories/GenericFactory';
import { DKProductDeclarationSegment } from '@/models/DKProductDeclarationSegment';
import { PropertyRequiredError } from '@/models/factories/PropertyRequiredError';
import { DKAsset } from '@/models/DKAsset';
import { AssetFactory } from '@/models/factories/AssetFactory';
import { DKProductDeclaration } from '@/models/DKProductDeclaration';
import { ProductDeclarationFactory } from '@/models/factories/ProductDeclarationFactory';

export class ProductDeclarationSegmentFactory extends GenericFactory<
  ProductDeclarationSegment,
  DKProductDeclarationSegment
> {
  contentType = 'ProductDeclarationSegment';
  requiredProperties = ['title'];

  protected map(
    source: ProductDeclarationSegment
  ): DKProductDeclarationSegment {
    let image: DKAsset | undefined;
    const declarations: DKProductDeclaration[] = [];

    if (source.image) {
      const assetFactory = new AssetFactory(this.locale);
      try {
        image = assetFactory.create(source.image);
      } catch (e: any) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate Image');
          console.warn(e.message);
        } else {
          throw e;
        }
      }
    }

    if (source.declarationsCollection?.items) {
      const productDeclarationFactory = new ProductDeclarationFactory(
        this.locale
      );
      const items: ProductDeclaration[] = source.declarationsCollection
        .items as ProductDeclaration[];
      items.forEach((item: ProductDeclaration) => {
        try {
          declarations.push(productDeclarationFactory.create(item));
        } catch (e: any) {
          if (e instanceof PropertyRequiredError) {
            console.warn('Could not generate Declaration');
            console.warn(e.message);
          } else {
            throw e;
          }
        }
      });
    }

    return Object.assign({} as DKProductDeclarationSegment, {
      ...source,
      image,
      declarations,
    });
  }
}
