import { Module } from 'vuex';
import { getters } from '@/store/app/getters';
import { actions } from '@/store/app/actions';
import { mutations } from '@/store/app/mutations';
import { GenericPage } from '../../../types/contentful-api';
import { PageHeaderType } from '@/store/app/models/PageHeaderType';
import { LOCALE } from '@/store/app/models/Locale';
import { DKStockPrice as DKStockPriceModel } from '@dormakaba/dormakaba-components/dist/types/lib/models/DKStockPrice';

export interface AppState {
  ui: {
    locale: LOCALE;
    isMenuOpen: boolean;
    hasBackdrop: boolean;
    headerType: PageHeaderType | null;
    showFooterGroup: boolean;
  };
  footer: {
    stockPrice: DKStockPriceModel | null;
  };
  verticalOverview: GenericPage | null;
}

const appModule: Module<AppState, any> = {
  state: () => ({
    ui: {
      locale: 'en-US',
      isMenuOpen: false,
      hasBackdrop: false,
      headerType: null,
      showFooterGroup: true,
    },
    footer: {
      stockPrice: null,
    },
    verticalOverview: null,
  }),
  getters,
  actions,
  mutations,
};

export default appModule;
