import { Link } from '../../../types/contentful-api';
import { GenericFactory } from '@/models/factories/GenericFactory';
import { DKLink } from '@/models/DKLink';
import { getSupportedLanguages } from '@/helpers/i18n';
import { isAbsoluteLink } from '@/helpers/link';
import { PropertyRequiredError } from '@/models/factories/PropertyRequiredError';

export class LinkFactory extends GenericFactory<Link, DKLink> {
  contentType = 'Link';
  requiredProperties = ['link', 'enLink', 'deLink', 'label'];

  private supportedLanguages: string[] = getSupportedLanguages();

  protected map(source: Link): DKLink {
    const anySource = source as any;
    if (!anySource.link) {
      throw new PropertyRequiredError(this.contentType, 'link', source);
    }
    if (!anySource.enLink) {
      throw new PropertyRequiredError(this.contentType, 'enLink', source);
    }
    if (!anySource.deLink) {
      throw new PropertyRequiredError(this.contentType, 'deLink', source);
    }

    const link = this.addLanguageIfMissing(anySource.link, anySource.enLink);
    const enLink = this.addLanguageIfMissing(
      anySource.enLink,
      anySource.enLink
    );
    const deLink = this.addLanguageIfMissing(
      anySource.deLink,
      anySource.enLink
    );

    return Object.assign({} as DKLink, {
      ...source,
      link,
      enLink,
      deLink,
    });
  }

  private addLanguageIfMissing(link: string, enLink: string) {
    if (!isAbsoluteLink(link)) {
      let isLanguageIncluded = false;

      this.supportedLanguages.forEach((language: string) => {
        if (link.startsWith(`/${language}`)) {
          isLanguageIncluded = true;
        }
      });

      if (!isLanguageIncluded) {
        if (link === enLink) {
          link = `/en${link}`;
        } else {
          link = `/de${link}`;
        }
      }
    }

    return link;
  }
}
