import { GenericFactory } from '@/models/factories/GenericFactory';
import { PropertyRequiredError } from '@/models/factories/PropertyRequiredError';
import { ValuePromise } from '../../../types/contentful-api';
import { DKAsset } from '@/models/DKAsset';
import { AssetFactory } from '@/models/factories/AssetFactory';
import { DKLink } from '@/models/DKLink';
import { LinkFactory } from '@/models/factories/LinkFactory';
import { DKValuePromise } from '@/models/DKValuePromise';

export class ValuePromiseFactory extends GenericFactory<
  ValuePromise,
  DKValuePromise
> {
  contentType = 'ValuePromise';
  requiredProperties = ['valuePromiseName', 'teaserText', 'image'];

  protected map(source: ValuePromise): DKValuePromise {
    const assetFactory = new AssetFactory(this.locale);
    const linkFactory = new LinkFactory(this.locale);

    let image: DKAsset | undefined;
    let link: DKLink | undefined;

    /***
     * Global
     */
    if (source?.image) {
      try {
        image = assetFactory.create(source.image);
      } catch (e: any) {
        if (e instanceof PropertyRequiredError) {
          console.warn(
            'Could not generate Image Asset within ValuePromiseFactory'
          );
          console.warn(e.message);
        } else {
          throw e;
        }
      }
    }

    /***
     * Country (Local)
     */
    if (source?.link) {
      try {
        link = linkFactory.create(source.link);
      } catch (e: any) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate Link within ValuePromiseFactory');
          console.warn(e.message);
        } else {
          throw e;
        }
      }
    }

    return Object.assign({} as DKValuePromise, {
      ...source,
      id: source?.sys.id,
      link,
      image,
    });
  }
}
