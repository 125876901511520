export type DKGenericShelfFragmentType =
  | 'Generic'
  | 'Achievements'
  | 'Awards/Brands'
  | 'Image Gallery'
  | 'Products'
  | 'Videos';

export enum DKGenericShelfFragmentTypes {
  Generic = 'Generic',
  Achievements = 'Achievements',
  'Awards/Brands' = 'AwardsBrands',
  'Image Gallery' = 'ImageGallery',
  Products = 'Products',
  Videos = 'Videos',
}
