import { Navigation } from '../../types/contentful-api';
import { NavigationTree } from '@/store/navigation/models/NavigationTree';
import { NavigationTreeItem } from '@/store/navigation/models/NavigationTreeItem';
import { notEmpty } from '@/helpers/not-empty';
import { DKNavigationItem } from '@/store/navigation/models/DKNavigationItem';
import { formatLink, isAbsoluteLink, isInternalLink } from '@/helpers/link';
import { LinkTargetType } from '@/models/LinkTargetType';
import { LOCALE } from '@/store/app/models/Locale';

export const buildNavigationTree = (
  navigation: Navigation,
  navigationItems: DKNavigationItem[],
  locale: LOCALE
): NavigationTree => {
  const tree: NavigationTree = [];
  findInTree(
    navigation.navigationItemsCollection?.items.filter(notEmpty) || [],
    navigationItems,
    tree,
    [],
    1,
    'root',
    locale
  );
  return tree;
};

function findInTree(
  items: DKNavigationItem[],
  allNavigationItems: DKNavigationItem[],
  tree: NavigationTree,
  breadcrumbs: string[],
  level: number,
  parent: string,
  locale: LOCALE
): void {
  if (level > 5) {
    /** base condition to prevent nesting loops */
    return;
  }

  for (const item of items) {
    const foundNavigationItem = allNavigationItems.find(
      (navigationItem) => navigationItem.sys.id === item.sys.id
    );
    if (!foundNavigationItem) {
      continue;
    }
    const isExternal =
      isAbsoluteLink(foundNavigationItem.link?.link) &&
      !isInternalLink(foundNavigationItem.link?.link);

    const treeItem: NavigationTreeItem = {
      id: foundNavigationItem.sys.id,
      label: foundNavigationItem.label,
      path: formatLink(foundNavigationItem.link?.link, locale),
      target: isExternal ? LinkTargetType.BLANK : LinkTargetType.SELF,
      expanded: foundNavigationItem.expanded,
      breadcrumbs: [...breadcrumbs, foundNavigationItem.sys.id],
      children: [],
      parent,
      level,
    };
    const children =
      foundNavigationItem.childrenCollection?.items.filter(notEmpty) || [];
    findInTree(
      children,
      allNavigationItems,
      treeItem.children,
      treeItem.breadcrumbs,
      level + 1,
      foundNavigationItem.sys.id,
      locale
    );
    tree.push(treeItem);
  }
}
