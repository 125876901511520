import { watch } from 'vue';
import type { Ref } from 'vue';

export const useLockScreen = (condition: Ref<boolean>) => {
  const setLockScreen = (value: boolean) => {
    const bodyApp = document.documentElement.querySelector('body');

    if (bodyApp) {
      bodyApp.style.overflow = value ? 'hidden' : 'auto';
    }
  };

  watch(
    () => condition.value,
    (val) => {
      setLockScreen(val);
    }
  );

  return { setLockScreen };
};
