import { GenericFactory } from '@/models/factories/GenericFactory';
import { DKStockPrice } from '@dormakaba/dormakaba-components/dist/types/lib/models/DKStockPrice';

export class StockPriceFactory extends GenericFactory<any, DKStockPrice> {
  contentType = 'StockPrice';
  requiredProperties = [
    'isin',
    'currency',
    'last_price',
    'change_relative',
    'change_absolute',
  ];

  protected map(source: any): DKStockPrice {
    return Object.assign({} as DKStockPrice, {
      ...source,
    });
  }
}
