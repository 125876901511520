import { DKAsset } from '@/models/DKAsset';
import { DKLink } from '@/models/DKLink';

export enum DKPopupRecurrence {
  EVERY = 'Every time',
  DAY = 'Once a day',
  WEEK = 'Once a week',
}

export interface DKPopup {
  __typename?: 'Popup';
  id: string;
  preTitle?: string;
  title: string;
  description?: string;
  image?: DKAsset;
  link: DKLink;
  recurrence: DKPopupRecurrence;
}
