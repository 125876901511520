import { ProductDeclaration } from '../../../types/contentful-api';
import { GenericFactory } from '@/models/factories/GenericFactory';
import { DKProductDeclaration } from '@/models/DKProductDeclaration';

export class ProductDeclarationFactory extends GenericFactory<
  ProductDeclaration,
  DKProductDeclaration
> {
  contentType = 'ProductDeclaration';
  requiredProperties = ['identifier', 'title', 'declarations'];

  protected map(source: ProductDeclaration): DKProductDeclaration {
    return Object.assign({} as DKProductDeclaration, source);
  }
}
