import { GenericFactory } from '@/models/factories/GenericFactory';
import {
  TeaserListFragment,
  TeaserListFragmentTeaserFragmentsItem,
} from '../../../types/contentful-api';
import { DKTeaserListFragment } from '@/models/DKTeaserListFragment';
import { PropertyRequiredError } from '@/models/factories/PropertyRequiredError';
import { DKTeaserListFragmentTeaser } from '@/models/DKTeaserListFragmentTeaser';
import { TeaserListFragmentPageTeaserFragmentFactory } from '@/models/factories/TeaserListFragmentPageTeaserFragmentFactory';
import { TeaserListFragmentTeaserFragmentFactory } from '@/models/factories/TeaserListFragmentTeaserFragmentFactory';

export class TeaserListFragmentFactory extends GenericFactory<
  TeaserListFragment,
  DKTeaserListFragment
> {
  contentType = 'TeaserListFragment';
  requiredProperties = [];

  protected map(source: TeaserListFragment): DKTeaserListFragment {
    const teasers: DKTeaserListFragmentTeaser[] = [];

    if (source.teaserFragmentsCollection?.items) {
      const items: TeaserListFragmentTeaserFragmentsItem[] = source
        .teaserFragmentsCollection
        .items as TeaserListFragmentTeaserFragmentsItem[];

      items.forEach((item: TeaserListFragmentTeaserFragmentsItem) => {
        switch (item.__typename) {
          case 'PageTeaserFragment': {
            try {
              const pageTeaserFragmentFactory =
                new TeaserListFragmentPageTeaserFragmentFactory(this.locale);
              teasers.push(pageTeaserFragmentFactory.create(item));
            } catch (e: any) {
              if (e instanceof PropertyRequiredError) {
                console.warn(
                  'Could not generate PageTeaserFragment within TeaserListFragment'
                );
                console.warn(e.message);
              } else {
                throw e;
              }
            }
            break;
          }
          case 'TeaserFragment': {
            try {
              const teaserFragmentFactory =
                new TeaserListFragmentTeaserFragmentFactory(this.locale);
              teasers.push(teaserFragmentFactory.create(item));
            } catch (e: any) {
              if (e instanceof PropertyRequiredError) {
                console.warn(
                  'Could not generate TeaserFragment within TeaserListFragment'
                );
                console.warn(e.message);
              } else {
                throw e;
              }
            }
            break;
          }
        }
      });
    }

    return Object.assign({} as DKTeaserListFragment, {
      ...source,
      teasers,
    });
  }
}
