import algoliasearch, { SearchIndex } from 'algoliasearch';
import { RequestOptions } from '@algolia/transporter';
import { GetObjectOptions, SearchResponse } from '@algolia/client-search';
import { createInMemoryCache } from '@algolia/cache-in-memory';
import { getLanguageFromLocale } from '@/helpers/i18n';
import { getEnv } from '@/env';
import { LOCALE } from '@/store/app/models/Locale';
import {
  EqsNewsItem,
  EqsNewsItemCategory,
} from '@dormakaba/search-type-definitions/dist/eqs/item';

const algoliaEQSIndexName = (lang: string): string => {
  if (!getEnv('ALGOLIA_EQS_CONTENT_INDEX_ID')) {
    return '';
  }
  return getEnv('ALGOLIA_EQS_CONTENT_INDEX_ID')
    .replace('{language}', lang)
    .toLowerCase();
};

const CACHE_CONFIGURATION = {
  requestsCache: createInMemoryCache({ serializable: false }),
  responsesCache: createInMemoryCache(),
};

/**
 * Initialize index, allowing multiples languages
 * @param index
 */
const initIndex = (
  locale: LOCALE,
  indexNameFn: (lang: string) => string
): SearchIndex => {
  const algoliaAppId = getEnv('ALGOLIA_ID') || '';
  const algoliaApiKey = getEnv('ALGOLIA_KEY') || '';
  const lang = getLanguageFromLocale(locale);
  const client = algoliasearch(algoliaAppId, algoliaApiKey, {
    ...CACHE_CONFIGURATION,
  });
  return client.initIndex(indexNameFn(lang));
};

/**
 * Get a single news from specified index
 * @param locale
 * @param requestOptions
 */
export const getEqsCategories = async (
  locale: LOCALE
): Promise<ReadonlyArray<EqsNewsItemCategory>> => {
  const index = initIndex(locale, algoliaEQSIndexName);

  try {
    const response = await index.search('', {
      facets: ['_eqs.categories.name', '_eqs.categories.slug'],
      hitsPerPage: 0,
    });

    const facets = response?.facets;

    if (
      !facets ||
      !facets['_eqs.categories.name'] ||
      !facets['_eqs.categories.slug']
    ) {
      return [];
    }

    const categoryNames = Object.keys(facets['_eqs.categories.name'] || {});
    const categorySlugs = Object.keys(facets['_eqs.categories.slug'] || {});

    // return a filtered list of valid categories (no null or empty)
    return categoryNames
      .map((name, index) => {
        const slug = categorySlugs[index];
        if (name && slug && name.trim() !== '' && slug.trim() !== '') {
          return { name, slug } as EqsNewsItemCategory;
        }
      })
      .filter(
        (category): category is EqsNewsItemCategory => category !== undefined
      )
      .sort((a, b) => a.name.localeCompare(b.name));
  } catch (err) {
    console.error('Error fetching categories:', err);
    return [];
  }
};

/**
 * Get a single news from specified index
 * @param locale
 * @param eqsNewsId
 * @param requestOptions
 */
export const getEqsNewsById = (
  locale: LOCALE,
  eqsNewsId: string,
  requestOptions?: RequestOptions & GetObjectOptions
): Readonly<Promise<EqsNewsItem>> => {
  const index = initIndex(locale, algoliaEQSIndexName);
  return index.getObject(eqsNewsId, requestOptions);
};

/**
 * Get a list of news from specified indexes list
 * @param locale
 * @param requestOptions
 */
export const getAllEqsNews = async (locale: LOCALE): Promise<EqsNewsItem[]> => {
  let hits: EqsNewsItem[] = [];
  const index = initIndex(locale ?? '', algoliaEQSIndexName);

  await index.browseObjects({
    query: '',
    batch: (batch: any) => {
      hits = hits.concat(batch);
    },
  });

  return hits;
};

export const getAllEqsNewsPaginated = async (
  locale: LOCALE,
  offset: number = 0,
  length: number = 10,
  categories: string[]
): Promise<{ items: EqsNewsItem[]; total: number }> => {
  const index = initIndex(locale, algoliaEQSIndexName);
  try {
    const facetFilters =
      categories && categories.length > 0
        ? categories.map((category) => `_eqs.categories.slug:${category}`)
        : [];
    const result = await index.search<EqsNewsItem>('', {
      offset,
      length,
      facetFilters: facetFilters.length > 0 ? facetFilters : undefined,
    });
    return { items: result.hits, total: result.nbHits };
  } catch (error) {
    console.error('Error fetching paginated news:', error);
    return { items: [], total: 0 };
  }
};

/**
 * Search news by search term
 * @param locale
 * @param query
 * @param requestOptions
 */
export const searchEqsNews = (
  locale: LOCALE | undefined,
  query: string,
  requestOptions?: RequestOptions & GetObjectOptions
): Readonly<Promise<SearchResponse<EqsNewsItem>>> => {
  const index = initIndex(locale ?? '', algoliaEQSIndexName);

  return index.search(query, {
    hitsPerPage: requestOptions?.queryParameters?.hitsPerPage,
    page: requestOptions?.queryParameters?.page,
  });
};
