import { ShortiesFragment, ShortySegment } from '../../../types/contentful-api';
import { GenericFactory } from '@/models/factories/GenericFactory';
import { DKShortiesFragment } from '@/models/DKShortiesFragment';
import { DKLink } from '@/models/DKLink';
import { PropertyRequiredError } from '@/models/factories/PropertyRequiredError';
import { LinkFactory } from '@/models/factories/LinkFactory';
import { ShortySegmentFactory } from '@/models/factories/ShortySegmentFactory';
import { DKShortySegment } from '@dormakaba/dormakaba-components/dist/types/lib/models/DKShortySegment';

export class ShortiesFragmentFactory extends GenericFactory<
  ShortiesFragment,
  DKShortiesFragment
> {
  contentType = 'ShortiesFragment';
  requiredProperties = ['shortTitle', 'style', 'title', 'shorties'];

  protected map(source: ShortiesFragment): DKShortiesFragment {
    let link: DKLink | undefined;
    const shorties: DKShortySegment[] = [];

    if (source.link) {
      const linkFactory = new LinkFactory(this.locale);
      try {
        link = linkFactory.create(source.link);
      } catch (e: any) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate Link');
          console.warn(e.message);
        } else {
          throw e;
        }
      }
    }

    if (source.shortiesCollection?.items) {
      const shortySegmentFactory = new ShortySegmentFactory(this.locale);
      const items: ShortySegment[] = source.shortiesCollection
        .items as ShortySegment[];
      items.forEach((item: ShortySegment) => {
        try {
          shorties.push(shortySegmentFactory.create(item));
        } catch (e: any) {
          if (e instanceof PropertyRequiredError) {
            console.warn('Could not generate ShortyFragment');
            console.warn(e.message);
          } else {
            throw e;
          }
        }
      });
    }

    return Object.assign({} as DKShortiesFragment, {
      ...source,
      link,
      shorties,
    });
  }
}
