import { headerHeight, idSelectorByHash } from '@/helpers/selector';

export const scrollTo = (hash?: string) => {
  if (!hash) {
    return;
  }
  setTimeout(() => {
    window.scrollTo({
      top: idSelectorByHash(hash) - headerHeight(idSelectorByHash(hash)),
      behavior: 'smooth',
    });
  }, 200);
};

export const scrollToTop = (
  element: HTMLElement | Window = window,
  delay = 0,
  animation = 'smooth'
) => {
  setTimeout(() => {
    element.scrollTo({
      top: 0,
      behavior: animation as ScrollBehavior,
    });
  }, delay);
};
