import {
  VideoFragment,
  VideoFragmentVideosItem,
} from '../../../types/contentful-api';
import { GenericFactory } from '@/models/factories/GenericFactory';
import { DKVideoFragment } from '@/models/DKVideoFragment';
import { DKVideoFragmentVideo } from '@/models/DKVideoFragmentVideo';
import { PropertyRequiredError } from '@/models/factories/PropertyRequiredError';
import { VideoFragmentVideoFactory } from '@/models/factories/VideoFragmentVideoFactory';

export class VideoFragmentFactory extends GenericFactory<
  VideoFragment,
  DKVideoFragment
> {
  contentType = 'VideoFragment';
  requiredProperties = ['id', 'shortTitle', 'title', 'videos'];

  protected map(source: VideoFragment): DKVideoFragment {
    let id: string | undefined;
    const videos: DKVideoFragmentVideo[] = [];
    let totalVideos = 0;

    if (source.sys.id) {
      id = source.sys.id;
    }

    if (source.videosCollection?.items) {
      const videoFragmentVideoFactory = new VideoFragmentVideoFactory(
        this.locale
      );

      const items: VideoFragmentVideosItem[] = source.videosCollection
        .items as VideoFragmentVideosItem[];

      items.forEach((video: VideoFragmentVideosItem) => {
        try {
          videos.push(videoFragmentVideoFactory.create(video));
        } catch (e: unknown) {
          if (e instanceof PropertyRequiredError) {
            console.warn('Could not generate Video');
            console.warn(e.message);
          } else {
            throw e;
          }
        }
      });
    }

    if (source.videosCollection?.total) {
      totalVideos = source.videosCollection.total;
    }

    return Object.assign({} as DKVideoFragment, {
      ...source,
      id,
      videos,
      totalVideos,
    });
  }
}
