import { Asset, Link, TeaserFragment } from '../../../types/contentful-api';
import { GenericFactory } from '@/models/factories/GenericFactory';
import { DKAsset } from '@/models/DKAsset';
import { DKLink } from '@/models/DKLink';
import { AssetFactory } from '@/models/factories/AssetFactory';
import { PropertyRequiredError } from '@/models/factories/PropertyRequiredError';
import { LinkFactory } from '@/models/factories/LinkFactory';
import { DKTeaserListFragmentTeaser } from '@/models/DKTeaserListFragmentTeaser';

export class TeaserListFragmentTeaserFragmentFactory extends GenericFactory<
  TeaserFragment,
  DKTeaserListFragmentTeaser
> {
  contentType = 'TeaserFragment';
  requiredProperties = ['style', 'title', 'linkResources', 'assetResources'];

  protected map(source: TeaserFragment): DKTeaserListFragmentTeaser {
    let image: DKAsset | undefined;
    const linkResources: DKLink[] = [];
    const assetResources: DKAsset[] = [];

    if (source.image) {
      const assetFactory = new AssetFactory(this.locale);
      try {
        image = assetFactory.create(source.image);
      } catch (e: any) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate Image Asset');
          console.warn(e.message);
        } else {
          throw e;
        }
      }
    }

    if (source.linkResourcesCollection?.items) {
      const linkFactory = new LinkFactory(this.locale);
      const items: Link[] = source.linkResourcesCollection.items as Link[];
      items.forEach((item: Link) => {
        try {
          linkResources.push(linkFactory.create(item));
        } catch (e: any) {
          if (e instanceof PropertyRequiredError) {
            console.warn('Could not generate LinkResources');
            console.warn(e.message);
          } else {
            throw e;
          }
        }
      });
    }

    if (source.assetResourcesCollection?.items) {
      const assetFactory = new AssetFactory(this.locale);
      const items: Asset[] = source.assetResourcesCollection.items as Asset[];
      items.forEach((item: Asset) => {
        try {
          assetResources.push(assetFactory.create(item));
        } catch (e: any) {
          if (e instanceof PropertyRequiredError) {
            console.warn('Could not generate AssetResource');
            console.warn(e.message);
          } else {
            throw e;
          }
        }
      });
    }

    return Object.assign({} as DKTeaserListFragmentTeaser, {
      id: source.sys.id,
      shortTitle: source.shortTitle,
      style: source.style,
      preTitle: source.preTitle,
      title: source.title,
      description: source.description,
      link: linkResources[0],
      image,
      linkResources: linkResources,
      assetResources: assetResources,
    });
  }
}
