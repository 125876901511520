import { Location } from '../../../types/contentful-api';
import { GenericFactory } from '@/models/factories/GenericFactory';
import { DKCoordinates } from '@/models/DKCoordinates';

export class CoordinatesFactory extends GenericFactory<
  Location,
  DKCoordinates
> {
  contentType = 'Coordinates';
  requiredProperties = ['lat', 'lon'];

  protected map(source: Location): DKCoordinates {
    return Object.assign({} as DKCoordinates, source);
  }
}
