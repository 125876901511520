import { GenericFactory } from '@/models/factories/GenericFactory';
import { PageTeaserFragment } from '../../../types/contentful-api';
import { DKTeaserListFragmentTeaser } from '@/models/DKTeaserListFragmentTeaser';
import { DKGenericPage } from '@/models/DKGenericPage';
import { GenericPageFactory } from '@/models/factories/GenericPageFactory';
import { PropertyRequiredError } from '@/models/factories/PropertyRequiredError';
import { DKLink } from '@/models/DKLink';

export class TeaserListFragmentPageTeaserFragmentFactory extends GenericFactory<
  PageTeaserFragment,
  DKTeaserListFragmentTeaser
> {
  contentType = 'PageTeaserFragment';
  requiredProperties = ['style', 'title'];

  protected map(source: PageTeaserFragment): DKTeaserListFragmentTeaser {
    let page: DKGenericPage | undefined;
    let link: DKLink | undefined;

    if (source.page) {
      const genericPageFactory = new GenericPageFactory(this.locale);
      try {
        page = genericPageFactory.create(source.page);
      } catch (e: any) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate Page');
          console.warn(e.message);
        } else {
          throw e;
        }
      }
    }

    if (page?.linkedFrom?.length) {
      link = page?.linkedFrom[0] || undefined;
    }

    return Object.assign({} as DKTeaserListFragmentTeaser, {
      id: source.sys.id,
      shortTitle: source.shortTitle,
      style: source.style,
      preTitle: page?.preTitle,
      title: page?.teaserTitle,
      description: page?.teaserText,
      link,
      image: page?.headerImage,
      linkResources: page?.linkResources,
      assetResources: page?.assetResources,
    });
  }
}
