import { VideoFragmentVideosItem } from '../../../types/contentful-api';
import { GenericFactory } from '@/models/factories/GenericFactory';
import { DKVideoFragmentVideo } from '@/models/DKVideoFragmentVideo';
import { DKVideo } from '@/models/DKVideo';
import { DKExternalVideo } from '@/models/DKExternalVideo';
import { PropertyRequiredError } from '@/models/factories/PropertyRequiredError';
import { VideoFactory } from '@/models/factories/VideoFactory';
import { ExternalVideoFactory } from '@/models/factories/ExternalVideoFactory';

export class VideoFragmentVideoFactory extends GenericFactory<
  VideoFragmentVideosItem,
  DKVideoFragmentVideo
> {
  contentType = 'VideoFragmentVideo';
  requiredProperties = [];

  protected map(source: VideoFragmentVideosItem): DKVideoFragmentVideo {
    let video: DKVideo | DKExternalVideo | undefined;

    const anySource = { ...source } as any;

    switch (source.__typename) {
      case 'ExternalVideo': {
        const externalVideoFactory = new ExternalVideoFactory(this.locale);
        try {
          video = externalVideoFactory.create({
            ...source,
            video: anySource.videoUrl,
          });
        } catch (e: any) {
          if (e instanceof PropertyRequiredError) {
            console.warn('Could not generate ExternalVideo');
            console.warn(e.message);
          } else {
            throw e;
          }
        }
        break;
      }
      case 'Video': {
        const videoFactory = new VideoFactory(this.locale);
        try {
          video = videoFactory.create({
            ...source,
            video: anySource.videoAsset,
          });
        } catch (e: any) {
          if (e instanceof PropertyRequiredError) {
            console.warn('Could not generate Video');
            console.warn(e.message);
          } else {
            throw e;
          }
        }
        break;
      }
    }

    if (!video) {
      throw new PropertyRequiredError(
        this.contentType,
        'VideoFragmentVideo',
        source
      );
    }

    return Object.assign({} as DKVideoFragmentVideo, { ...video });
  }
}
