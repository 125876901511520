import { Asset, Management } from '../../../types/contentful-api';
import { GenericFactory } from '@/models/factories/GenericFactory';
import { DKManagement } from '@/models/DKManagement';
import { DKAsset } from '@/models/DKAsset';
import { AssetFactory } from '@/models/factories/AssetFactory';
import { PropertyRequiredError } from '@/models/factories/PropertyRequiredError';

export class ManagementFactory extends GenericFactory<
  Management,
  DKManagement
> {
  contentType = 'Management';
  requiredProperties = ['slug', 'firstName', 'lastName', 'function', 'image'];

  protected map(source: Management): DKManagement {
    let image: DKAsset | undefined;
    const downloads: DKAsset[] = [];
    let metaImage: DKAsset | undefined;
    const assetFactory = new AssetFactory(this.locale);

    if (source.image) {
      try {
        image = assetFactory.create(source.image);
      } catch (e) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate Image');
          console.warn(e);
        } else {
          throw e;
        }
      }
    }

    if (source.downloadsCollection?.items) {
      const items: Asset[] = source.downloadsCollection.items as Asset[];
      items.forEach((download: Asset) => {
        try {
          downloads.push(assetFactory.create(download));
        } catch (e) {
          if (e instanceof PropertyRequiredError) {
            console.warn('Could not generate Download');
            console.warn(e);
          } else {
            throw e;
          }
        }
      });
    }

    if (source.metaImage) {
      try {
        metaImage = assetFactory.create(source.metaImage);
      } catch (e) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate MetaImage');
          console.warn(e);
        } else {
          throw e;
        }
      }
    }

    return Object.assign({} as DKManagement, {
      ...source,
      id: source.sys.id,
      image,
      downloads,
      metaImage,
    });
  }
}
