import { ContactFragment, Employee } from '../../../types/contentful-api';
import { GenericFactory } from '@/models/factories/GenericFactory';
import { DKContactFragment } from '@/models/DKContactFragment';
import { DKEmployee } from '@/models/DKEmployee';
import { EmployeeFactory } from '@/models/factories/EmployeeFactory';
import { PropertyRequiredError } from '@/models/factories/PropertyRequiredError';

export class ContactFragmentFactory extends GenericFactory<
  ContactFragment,
  DKContactFragment
> {
  contentType = 'ContactFragment';
  requiredProperties = ['shortTitle', 'title'];

  protected map(source: ContactFragment): DKContactFragment {
    const contacts: DKEmployee[] = [];
    const employeeFactory = new EmployeeFactory(this.locale);

    if (source.contactsCollection?.items) {
      const items: Employee[] = source.contactsCollection.items as Employee[];
      items.forEach((employee: Employee) => {
        try {
          contacts.push(employeeFactory.create(employee));
        } catch (e) {
          if (e instanceof PropertyRequiredError) {
            console.warn('Could not generate Contact');
            console.warn(e.message);
          } else {
            throw e;
          }
        }
      });
    }

    return Object.assign({} as DKContactFragment, { ...source, contacts });
  }
}
