import { ReportTopic } from '../../../types/contentful-api';
import { GenericFactory } from '@/models/factories/GenericFactory';
import { DKReportTopic } from '@/models/DKReportTopic';

export class ReportTopicFactory extends GenericFactory<
  ReportTopic,
  DKReportTopic
> {
  contentType = 'ReportTopic';
  requiredProperties = ['slug', 'label'];

  protected map(source: ReportTopic): DKReportTopic {
    return Object.assign({} as DKReportTopic, source);
  }
}
