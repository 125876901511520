import { MutationTree } from 'vuex';
import { ContentfulInfoState } from '@/store/contentful-info';

export const mutations: MutationTree<ContentfulInfoState> = {
  setContentfulInfo: (
    state: ContentfulInfoState,
    payload: ContentfulInfoState
  ) => {
    state.global = payload.global;
    state.local = payload.local;
  },
};
