import { Vue } from 'vue-facing-decorator';
import { decodeJWT } from './helpers/jwt';

export const getEnv = (name: string) => {
  // Cases explained:
  // If the page is served through SSR, then executed in the Browser, window.__APP_CONFIG is taken
  // If the page is served without SSR, executed in the Browser, envFallback is taken
  // If the page is executed within SSR, Vue.prototype.$env is taken

  if (typeof window !== 'undefined') {
    let decodedConfig;
    // @ts-ignore
    const windowConfig = window.__APP_CONFIG__;
    if (windowConfig) {
      decodedConfig = decodeJWT(windowConfig);
    }
    // @ts-ignore
    return decodedConfig?.[name] || envFallback[name];
  }
  // @ts-ignore
  return Vue.prototype.$env[name];
};
