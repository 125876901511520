import { UseCaseVideo } from '../../../types/contentful-api';
import { GenericFactory } from '@/models/factories/GenericFactory';
import { DKVideo } from '@/models/DKVideo';
import { DKExternalVideo } from '@/models/DKExternalVideo';
import { PropertyRequiredError } from '@/models/factories/PropertyRequiredError';
import { VideoFactory } from '@/models/factories/VideoFactory';
import { ExternalVideoFactory } from '@/models/factories/ExternalVideoFactory';
import { DKUseCaseVideo } from '@/models/DKUseCaseVideo';

export class UseCaseVideoFactory extends GenericFactory<
  UseCaseVideo,
  DKUseCaseVideo
> {
  contentType = 'UseCaseVideo';
  requiredProperties = [];

  protected map(source: UseCaseVideo): DKUseCaseVideo {
    let video: DKVideo | DKExternalVideo | undefined;

    const anySource = { ...source } as any;

    switch (source.__typename) {
      case 'ExternalVideo': {
        const externalVideoFactory = new ExternalVideoFactory(this.locale);
        try {
          video = externalVideoFactory.create({
            ...source,
            video: anySource.videoUrl,
          });
        } catch (e: any) {
          if (e instanceof PropertyRequiredError) {
            console.warn('Could not generate ExternalVideo');
            console.warn(e.message);
          } else {
            throw e;
          }
        }
        break;
      }
      case 'Video': {
        const videoFactory = new VideoFactory(this.locale);
        try {
          video = videoFactory.create({
            ...source,
            video: anySource.videoAsset,
          });
        } catch (e: any) {
          if (e instanceof PropertyRequiredError) {
            console.warn('Could not generate Video');
            console.warn(e.message);
          } else {
            throw e;
          }
        }
        break;
      }
    }

    if (!video) {
      throw new PropertyRequiredError(this.contentType, 'UseCaseVideo', source);
    }

    return Object.assign({} as DKUseCaseVideo, { ...video });
  }
}
