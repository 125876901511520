import { Module } from 'vuex';
import { blogGetters } from '@/store/blog/getters';
import { mutations } from '@/store/blog/mutations';
import { DKBlogArticles } from '@/store/blog/models/DKBlogArticles';
import { DKBlogItem } from '@/models/DKBlogItem';

export interface BlogState {
  articles: DKBlogArticles;
  localizedArticles: DKBlogItem[];
}

const blogModule: Module<BlogState, any> = {
  state: () => ({
    articles: {} as DKBlogArticles,
    localizedArticles: [],
  }),
  getters: blogGetters,
  mutations,
  namespaced: true,
};

export default blogModule;
