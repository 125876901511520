import { GenericFactory } from '@/models/factories/GenericFactory';
import { Report } from '../../../types/contentful-api';
import { DKReport } from '@/models/DKReport';
import { PropertyRequiredError } from '@/models/factories/PropertyRequiredError';
import { ReportTopicFactory } from '@/models/factories/ReportTopicFactory';
import { DKReportTopic } from '@/models/DKReportTopic';

export class ReportFactory extends GenericFactory<Report, DKReport> {
  protected contentType = 'Report';
  protected requiredProperties = ['title', 'publicationDate', 'report'];

  protected map(source: Report): DKReport {
    let reportTopic: DKReportTopic | undefined;

    if (source.reportTopic) {
      const reportTopicFactory = new ReportTopicFactory(this.locale);
      try {
        reportTopic = reportTopicFactory.create(source.reportTopic);
      } catch (e: any) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate ReportTopic');
          console.warn(e.message);
        } else {
          throw e;
        }
      }
    }

    return Object.assign({} as DKReport, { ...source, reportTopic });
  }
}
