import { ExternalVideo } from '../../../types/contentful-api';
import { GenericFactory } from '@/models/factories/GenericFactory';
import { DKExternalVideo } from '@/models/DKExternalVideo';
import { DKAsset } from '@/models/DKAsset';
import { AssetFactory } from '@/models/factories/AssetFactory';
import { PropertyRequiredError } from '@/models/factories/PropertyRequiredError';

export class ExternalVideoFactory extends GenericFactory<
  ExternalVideo,
  DKExternalVideo
> {
  contentType = 'ExternalVideo';
  requiredProperties = ['title', 'video'];

  protected map(source: ExternalVideo): DKExternalVideo {
    let thumbnail: DKAsset | undefined;

    if (source.thumbnail) {
      const assetFactory = new AssetFactory(this.locale);
      try {
        thumbnail = assetFactory.create(source.thumbnail);
      } catch (e) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate Thumbnail');
          console.warn(e.message);
        } else {
          throw e;
        }
      }
    }

    return Object.assign({} as DKExternalVideo, { ...source, thumbnail });
  }
}
