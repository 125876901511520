import { Asset } from '../../../types/contentful-api';
import { GenericFactory } from '@/models/factories/GenericFactory';
import { DKAsset } from '@/models/DKAsset';

export class AssetFactory extends GenericFactory<Asset, DKAsset> {
  contentType = 'Asset';
  requiredProperties = ['title', 'fileName', 'size', 'url'];

  protected map(source: Asset): DKAsset {
    return Object.assign({} as DKAsset, source);
  }
}
