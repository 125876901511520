import gql from 'graphql-tag';

export const localGenericShelfFragmentQuery = (resourceLink: string) => gql`
  query (
    $id: String!
    $locale: String = "en-US"
    $preview: Boolean = false
    $skip: Int
    $limit: Int
  ) {
    genericShelfFragment(id: $id, locale: $locale, preview: $preview) {
      sys {
        id
      }
      shortTitle
      preTitle
      title
      description
      type
      background
      genericShelfElementsCollection(skip: $skip, limit: $limit) {
        items {
          ... on GenericShelfElement {
            sys {
              id
            }
            title
            description
            genericElementLink: link
            image {
              title
              fileName
              size
              url
              contentType
            }
            video {
              ... on Video {
                sys {
                  id
                }
                title
                caption
                videoAsset: video {
                  title
                  fileName
                  url
                  size
                  description
                }
                thumbnail {
                  title
                  fileName
                  url
                  size
                  description
                }
                fieldOfExpertise
                region
              }
              ... on ExternalVideo {
                sys {
                  id
                }
                title
                caption
                videoUrl: video
                thumbnail {
                  title
                  fileName
                  url
                  size
                  description
                  contentType
                }
              }
            }
          }
          ... on UseCase {
            sys {
              id
            }
            slug
            title
            objectName
            country
            isPage
            headerImage {
              title
              fileName
              size
              url
              contentType
            }
          }
        }
      }
         # The locale 'de-DE' does not exist in global space. Using default locale to fetch global ids
        globalGenericShelfElements(skip: $skip, limit: $limit, locale: "en-US") {
      items {
          node {
            ... on ${resourceLink} {
              sys {
              id
            }
            }
          }
      } 
    }
    }
    
  }
`;
