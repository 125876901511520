import { GenericFactory } from '@/models/factories/GenericFactory';
import {
  ReportFilterFragment,
  ReportTopic,
} from '../../../types/contentful-api';
import { DKReportFilterFragment } from '@/models/DKReportFilterFragment';
import { PropertyRequiredError } from '@/models/factories/PropertyRequiredError';
import { ReportTopicFactory } from '@/models/factories/ReportTopicFactory';
import { DKReportTopic } from '@/models/DKReportTopic';

export class ReportFilterFragmentFactory extends GenericFactory<
  ReportFilterFragment,
  DKReportFilterFragment
> {
  protected contentType = 'ReportFilterFragment';
  protected requiredProperties = ['shortTitle', 'title'];

  protected map(source: ReportFilterFragment): DKReportFilterFragment {
    const reportTopics: DKReportTopic[] = [];

    if (source.reportTopicsCollection?.items) {
      const items: ReportTopic[] = source.reportTopicsCollection
        .items as ReportTopic[];

      const reportTopicFactory = new ReportTopicFactory(this.locale);
      try {
        for (const reportTopic of items) {
          reportTopics.push(reportTopicFactory.create(reportTopic));
        }
      } catch (e: any) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate ReportTopic');
          console.warn(e.message);
        } else {
          throw e;
        }
      }
    }

    return Object.assign({} as DKReportFilterFragment, {
      ...source,
      reportTopics,
    });
  }
}
