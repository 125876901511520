import { PageTeaserFragment } from '../../../types/contentful-api';
import { GenericFactory } from '@/models/factories/GenericFactory';
import { DKPageTeaserFragment } from '@/models/DKPageTeaserFragment';
import { DKGenericPage } from '@/models/DKGenericPage';
import { GenericPageFactory } from '@/models/factories/GenericPageFactory';
import { PropertyRequiredError } from '@/models/factories/PropertyRequiredError';

export class PageTeaserFragmentFactory extends GenericFactory<
  PageTeaserFragment,
  DKPageTeaserFragment
> {
  contentType = 'PageTeaserFragment';
  requiredProperties = ['style', 'page'];

  protected map(source: PageTeaserFragment): DKPageTeaserFragment {
    let page: DKGenericPage | undefined;

    if (source.page) {
      const genericPageFactory = new GenericPageFactory(this.locale);
      try {
        page = genericPageFactory.create(source.page);
      } catch (e: any) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate Page');
          console.warn(e.message);
        } else {
          throw e;
        }
      }
    }

    return Object.assign({} as DKPageTeaserFragment, {
      ...source,
      page,
    });
  }
}
