import { GenericFactory } from '@/models/factories/GenericFactory';
import { LatestNewsFragment } from '../../../types/contentful-api';
import { DKLatestNewsFragment } from '@/models/DKLatestNewsFragment';

export class LatestNewsFragmentFactory extends GenericFactory<
  LatestNewsFragment,
  DKLatestNewsFragment
> {
  contentType = 'LatestNewsFragment';
  requiredProperties = ['shortTitle', 'title'];

  protected map(source: LatestNewsFragment): DKLatestNewsFragment {
    return Object.assign({} as DKLatestNewsFragment, source);
  }
}
