import { CtaFragment } from '../../../types/contentful-api';
import { GenericFactory } from '@/models/factories/GenericFactory';
import { DKCtaFragment } from '@/models/DKCtaFragment';
import { DKLink } from '@/models/DKLink';
import { LinkFactory } from '@/models/factories/LinkFactory';
import { PropertyRequiredError } from '@/models/factories/PropertyRequiredError';

export class CtaFragmentFactory extends GenericFactory<
  CtaFragment,
  DKCtaFragment
> {
  contentType = 'CtaFragment';
  requiredProperties = ['title', 'link', 'background', 'size'];

  protected map(source: CtaFragment): DKCtaFragment {
    let link: DKLink | undefined;

    if (source.link) {
      const linkFactory = new LinkFactory(this.locale);
      try {
        link = linkFactory.create(source.link);
      } catch (e) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate Link');
          console.warn(e.message);
        } else {
          throw e;
        }
      }
    }

    return Object.assign({} as DKCtaFragment, { ...source, link });
  }
}
