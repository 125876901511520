import { UseCaseOverviewFragment } from '../../../types/global-contentful-api';
import { GenericFactory } from '@/models/factories/GenericFactory';
import { DKUseCaseOverviewFragment } from '@/models/DKUseCaseOverviewFragment';

export class UseCaseOverviewFragmentFactory extends GenericFactory<
  UseCaseOverviewFragment,
  DKUseCaseOverviewFragment
> {
  contentType = 'UseCaseOverviewFragment';
  requiredProperties = ['shortTitle'];

  protected map(source: UseCaseOverviewFragment): DKUseCaseOverviewFragment {
    return Object.assign({} as DKUseCaseOverviewFragment, { ...source });
  }
}
