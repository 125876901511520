import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6630771c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "contact-footer" }
const _hoisted_2 = { class: "contact-footer__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContactButtonList = _resolveComponent("ContactButtonList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('footer.contact.title')), 1),
    _createVNode(_component_ContactButtonList, {
      contactNavigationTree: _ctx.contactFooterNavigationTree,
      isInverted: true,
      onClickContactLink: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('clickContactLink', $event)))
    }, null, 8, ["contactNavigationTree"])
  ]))
}