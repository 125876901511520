import { LOCALE } from '@/store/app/models/Locale';
import { LocaleMessages, VueMessageType } from 'vue-i18n';
import { createI18n } from '../node_modules/vue-i18n/dist/vue-i18n.esm-bundler.js';
import { getEnv } from '@/env';
import { getFallbackLocale, getSupportedLocales } from '@/helpers/i18n';

function loadAsync(locale: LOCALE) {
  return import(
    /* webpackChunkName: "[request]" */ `@/locales/${locale}.json`
  ).then((messages: any) => ({
    locale,
    messages: messages.default,
  }));
}

async function loadLocaleMessages(
  remoteMessages?: LocaleMessages<VueMessageType>
): Promise<LocaleMessages<VueMessageType>> {
  if (remoteMessages && Object.keys(remoteMessages).length) {
    return Promise.resolve(remoteMessages);
  } else {
    // fallback in case on POEditor request failing
    console.warn('No remote translations were found, fallback used!');

    const messages: LocaleMessages<VueMessageType> = {};
    const locales = new Set([...getSupportedLocales(), getFallbackLocale()]);
    const pendingPromises: any[] = [];

    locales.forEach((locale: LOCALE) => {
      pendingPromises.push(loadAsync(locale));
    });

    const promises = await Promise.allSettled(pendingPromises);

    promises.forEach((promise: any) => {
      if (promise.status === 'fulfilled') {
        messages[promise.value.locale] = promise.value.messages;
      }
    });
    return messages;
  }
}

export default async function (
  remoteMessages?: LocaleMessages<VueMessageType>,
  locale: LOCALE = getEnv('I18N_LOCALE') as string,
  fallbackLocale: LOCALE = getEnv('I18N_FALLBACK_LOCALE') as string
) {
  const messages: LocaleMessages<VueMessageType> = await loadLocaleMessages(
    remoteMessages
  );
  return createI18n({
    globalInjection: true,
    legacy: false,
    locale,
    fallbackLocale,
    messages,
  });
}
