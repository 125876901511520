import { NewsOverviewFragment } from '../../../types/contentful-api';
import { GenericFactory } from '@/models/factories/GenericFactory';
import { DKNewsOverviewFragment } from '@/models/DKNewsOverviewFragment';

export class NewsOverviewFragmentFactory extends GenericFactory<
  NewsOverviewFragment,
  DKNewsOverviewFragment
> {
  contentType = 'NewsOverviewFragment';
  requiredProperties = ['shortTitle'];

  protected map(source: NewsOverviewFragment): DKNewsOverviewFragment {
    return Object.assign({} as DKNewsOverviewFragment, source);
  }
}
