import { Module } from 'vuex';
import { getters } from '@/store/contentful-info/getters';
import { mutations } from '@/store/contentful-info/mutations';

export interface ContentfulInfoState {
  local: { locales: Array<string>; localeMap: Record<string, string> };
  global: { locales: Array<string>; localeMap: Record<string, string> };
}

const contentfulInfoModule: Module<ContentfulInfoState, any> = {
  state: () => ({
    local: { locales: [], localeMap: {} },
    global: { locales: [], localeMap: {} },
  }),
  getters,
  mutations,
  namespaced: true,
};
export default contentfulInfoModule;
