import { GenericFactory } from '@/models/factories/GenericFactory';
import { DKAsset } from '@/models/DKAsset';
import { DKReusablePage } from '@/models/DKReusablePage';
import { ReusablePage } from '../../../types/contentful-api';
import { AssetFactory } from '@/models/factories/AssetFactory';
import { PropertyRequiredError } from '@/models/factories/PropertyRequiredError';

export class ReusablePageFactory extends GenericFactory<
  ReusablePage,
  DKReusablePage
> {
  contentType = 'ReusablePage';
  requiredProperties = [];

  protected map(source: ReusablePage): DKReusablePage {
    let headerImage: DKAsset | undefined;
    const assetFactory = new AssetFactory(this.locale);
    let metaImage: DKAsset | undefined;

    if (source.headerImage) {
      try {
        headerImage = assetFactory.create(source.headerImage);
      } catch (e) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate HeaderImage');
          console.warn(e);
          console.info(e.data);
        } else {
          throw e;
        }
      }
    }

    if (source.metaImage) {
      try {
        metaImage = assetFactory.create(source.metaImage);
      } catch (e) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate MetaImage');
          console.warn(e);
        } else {
          throw e;
        }
      }
    }

    return Object.assign({} as DKReusablePage, {
      ...source,
      headerImage,
      metaImage,
    });
  }
}
