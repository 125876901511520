import {
  createClient,
  ContentfulClientApi,
  CreateClientParams,
} from 'contentful';
import { getEnv } from '@/env';
import { DKSpaceOriginType } from '@/models/DKSpaceOrigin';

const getConfig = (origin: DKSpaceOriginType): CreateClientParams => ({
  host:
    getEnv('ENABLE_PREVIEW') === 'true'
      ? 'preview.contentful.com'
      : 'cdn.contentful.com',
  space:
    (origin === DKSpaceOriginType.LOCAL_SPACE
      ? getEnv('CONTENTFUL_SPACE_ID')
      : getEnv('CONTENTFUL_GLOBAL_SPACE_ID')) || '',
  accessToken:
    (origin === DKSpaceOriginType.LOCAL_SPACE
      ? getEnv('CONTENTFUL_ACCESS_TOKEN')
      : getEnv('CONTENTFUL_GLOBAL_ACCESS_TOKEN')) || '',
  environment:
    (origin === DKSpaceOriginType.LOCAL_SPACE
      ? getEnv('CONTENTFUL_ENV')
      : getEnv('CONTENTFUL_GLOBAL_ENVIRONMENT')) || 'master',
});

export function getContentfulGraphQlClient(
  origin: DKSpaceOriginType
): ContentfulClientApi {
  const config = getConfig(origin);
  return createClient(config);
}
