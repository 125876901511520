import {
  MediaGalleryFragment,
  MediaGalleryFragmentSegmentsItem,
} from '../../../types/contentful-api';
import { GenericFactory } from '@/models/factories/GenericFactory';
import { DKMediaGalleryFragmentSegmentsItem } from '@/models/DKMediaGalleryFragmentSegmentsItem';
import { DKMediaGalleryFragment } from '@/models/DKMediaGalleryFragment';
import { PropertyRequiredError } from '@/models/factories/PropertyRequiredError';
import { MediaGallerySegmentFactory } from '@/models/factories/MediaGallerySegmentFactory';
import { ManagementGallerySegmentFactory } from '@/models/factories/ManagementGallerySegmentFactory';

export class MediaGalleryFragmentFactory extends GenericFactory<
  MediaGalleryFragment,
  DKMediaGalleryFragment
> {
  contentType = 'MediaGalleryFragment';
  requiredProperties = ['shortTitle', 'title'];

  protected map(source: MediaGalleryFragment): DKMediaGalleryFragment {
    const segments: DKMediaGalleryFragmentSegmentsItem[] = [];

    if (source.segmentsCollection?.items) {
      const items: MediaGalleryFragmentSegmentsItem[] = source
        .segmentsCollection.items as MediaGalleryFragmentSegmentsItem[];
      const managementGallerySegmentFactory =
        new ManagementGallerySegmentFactory(this.locale);
      const mediaGallerySegmentFactory = new MediaGallerySegmentFactory(
        this.locale
      );

      items.forEach((segment: MediaGalleryFragmentSegmentsItem) => {
        switch (segment.__typename) {
          case 'ManagementGallerySegment':
            try {
              segments.push(managementGallerySegmentFactory.create(segment));
            } catch (e) {
              if (e instanceof PropertyRequiredError) {
                console.warn('Could not generate ManagementGallerySegment');
                console.warn(e);
              } else {
                throw e;
              }
            }
            break;
          case 'MediaGallerySegment':
            try {
              segments.push(mediaGallerySegmentFactory.create(segment));
            } catch (e) {
              if (e instanceof PropertyRequiredError) {
                console.warn('Could not generate MediaGallerySegment');
                console.warn(e);
              } else {
                throw e;
              }
            }
            break;
        }
      });
    }

    return Object.assign({} as DKMediaGalleryFragment, {
      ...source,
      segments,
    });
  }
}
