import { GenericFactory } from '@/models/factories/GenericFactory';
import { LatestBlogArticlesFragment } from '../../../types/contentful-api';
import { DKLatestBlogArticlesFragment } from '@/models/DKLatestBlogArticlesFragment';

export class LatestBlogArticlesFragmentFactory extends GenericFactory<
  LatestBlogArticlesFragment,
  DKLatestBlogArticlesFragment
> {
  contentType = 'LatestBlogArticlesFragment';
  requiredProperties = ['shortTitle', 'title', 'background'];

  protected map(
    source: LatestBlogArticlesFragment
  ): DKLatestBlogArticlesFragment {
    return Object.assign({} as DKLatestBlogArticlesFragment, {
      ...source,
    });
  }
}
