import { GetterTree } from 'vuex';
import { NavigationState } from '@/store/navigation/index';
import {
  Link,
  Navigation,
  NavigationItem,
  NavigationItemPopupNode,
  Popup,
} from '../../../types/contentful-api';
import { searchTreeItemByPath } from '@/helpers/search-navigation-tree';
import { buildNavigationTree } from '@/helpers/navigation-tree';
import { NavigationTree } from '@/store/navigation/models/NavigationTree';
import { NavigationTreeItem } from '@/store/navigation/models/NavigationTreeItem';
import { PropertyRequiredError } from '@/models/factories/PropertyRequiredError';
import { NavbarTransparentElement } from '@/models/navigation/NavbarTypes';
import { PopupFactory } from '@/models/factories/PopupFactory';
import { DKSpaceSource } from '@/models/DKSpaceSource';
import { StringMap } from '@/models/StringMap';
import { DKPopup } from '@/models/DKPopup';

export const navigationGetters: GetterTree<NavigationState, any> = {
  isReady: (state: NavigationState): boolean => {
    return state.ready;
  },
  navigationItems: (state: NavigationState): NavigationItem[] => {
    return state.navigationItems.data;
  },
  navigationItem:
    (state: NavigationState, getters) =>
    (menuSelect: string): NavigationItem | undefined => {
      return getters['navigationItems'].find(
        (navigationItem: NavigationItem) =>
          navigationItem?.link?.link === menuSelect
      );
    },
  navigations: (state: NavigationState): Navigation[] => {
    return state.navigations.data;
  },
  navigation:
    (state: NavigationState) =>
    (menuSelect: string): Navigation | undefined => {
      return state.navigations.data.find(
        (navigation: Navigation) => navigation.menuSelect === menuSelect
      );
    },
  navigationTree:
    (state: NavigationState, getters, rootState) =>
    (menuSelect: string): NavigationTree => {
      const locale = rootState.app.ui.locale;
      const navigation: Navigation = getters['navigation'](menuSelect);
      const navigationItems: NavigationItem[] = getters['navigationItems'];
      if (!navigation) {
        return [];
      }
      return buildNavigationTree(navigation, navigationItems, locale);
    },
  navigationTreeItem:
    () =>
    (menuSelect: string, tree: NavigationTree): NavigationTreeItem | null => {
      return searchTreeItemByPath(menuSelect, tree);
    },
  links: (state: NavigationState): Link[] => {
    return state.links.data;
  },
  linkBySlug:
    (state: NavigationState, getters) =>
    (slug: string): Link | undefined => {
      return getters['links'].find(
        (link: Link) => link && link.link && link.link === `/${slug}`
      );
    },
  currentLink: (state: NavigationState): Link | null => {
    return state.currentLink;
  },
  currentSlugs: (state: NavigationState): StringMap | null => {
    return state.currentSlugs;
  },
  popupsCollection: (state: NavigationState): Record<string, Popup | never> => {
    return state.popupsCollection;
  },
  popupByPath:
    (_, getters, rootState) =>
    (path: string): DKPopup | null => {
      const currentLink = getters['links'].find(
        (linkItem: DKSpaceSource<Link>) =>
          linkItem && linkItem.link === `/${path}`
      );

      const currentNavigationItem = getters['navigationItems'].find(
        (navItem: NavigationItem) =>
          navItem.link?.sys.id === currentLink?.sys.id
      );
      if (!currentNavigationItem) return null;

      const { popup: globalPopup, localPopup } = currentNavigationItem;

      const findPopupById = (
        el: Popup | NavigationItemPopupNode
      ): Popup | undefined => {
        const popupId = el.sys.id;
        return popupId ? getters['popupsCollection'][popupId] : undefined;
      };

      const navigationPopup = globalPopup?.node ?? localPopup;
      if (!navigationPopup) return null;

      const locale = rootState.app.ui.locale;
      const popupFactory = new PopupFactory(locale);

      const generatedPopup = findPopupById(navigationPopup);
      let popup: DKPopup | null = null;

      if (generatedPopup) {
        try {
          popup = popupFactory.create(generatedPopup);
        } catch (e) {
          if (e instanceof PropertyRequiredError) {
            console.warn('Could not generate Popup');
            console.warn(e);
          } else {
            throw e;
          }
        }
      }

      return popup;
    },
  popup: (state: NavigationState): DKPopup | null => {
    return state.currentPopup;
  },
  pageIdBySlug:
    (state: NavigationState) =>
    (payload: string): string | null => {
      const navigationItems = state.navigationItems.data;
      const navigationItem = navigationItems.find(
        (currentItem: NavigationItem) =>
          currentItem?.link?.link === `/${payload}`
      );
      return navigationItem && navigationItem.link && navigationItem.link.page
        ? navigationItem.link.page.sys.id
        : null;
    },
  isLocationSwitcherOpen: (state: NavigationState) => (): boolean => {
    return state.locationSwitcherExpanded;
  },
  isNavbarHidden: (state: NavigationState): boolean => state.navbarHidden,
  navbarTransparentElement: (
    state: NavigationState
  ): NavbarTransparentElement => state.transparentElement,
};
