<script setup lang="ts">
import { toRefs, computed } from 'vue';

import { LOCALE } from '@dormakaba/dormakaba-components/dist/types/lib/models/Locale';
import { DKStockPrice as DKStockPriceModel } from '@dormakaba/dormakaba-components/dist/types/lib/models/DKStockPrice';
import { DKProject } from '@/models/DKProjects';

interface Props {
  stockPrice: DKStockPriceModel;
  locale?: LOCALE;
  type?: DKProject;
}

enum StockPriceChangeValue {
  Positive = 'positive',
  Negative = 'negative',
}

const props = withDefaults(defineProps<Props>(), {
  locale: 'en-US',
  type: DKProject.Country,
});

const { stockPrice } = toRefs(props);

const isIn = computed<string>(() => stockPrice.value.isin);

const lastPrice = computed<string>(() =>
  parseFloat(`${stockPrice.value.last_price}`).toFixed(2)
);

const currency = computed<string>(() => stockPrice.value.currency);

const changeAbsolute = computed<number>(
  () => +stockPrice.value.change_absolute || 0
);

const changeRelative = computed<string>(
  () => stockPrice.value.change_relative || '0'
);

const priceChange = computed<string>(() => {
  const changeRelativePostfix = changeRelative.value.includes('%') ? '' : '%';
  const plusSign =
    changeValue.value === StockPriceChangeValue.Positive ? '+' : '';

  return `${currency.value} ${plusSign}${changeAbsolute.value.toFixed(
    2
  )} (${plusSign}${changeRelative.value}${changeRelativePostfix})`;
});

const changeValue = computed<StockPriceChangeValue | undefined>(() => {
  const options = {
    [StockPriceChangeValue.Negative]: changeAbsolute.value < 0,
    [StockPriceChangeValue.Positive]: changeAbsolute.value > 0,
  };

  return Object.keys(options).find(
    (key) => options[key as StockPriceChangeValue]
  ) as StockPriceChangeValue;
});

const dateTime = computed<string>(() => {
  const date = new Date(stockPrice.value.time_last_price * 1000);

  return date.toLocaleDateString(props.locale, {
    timeZone: 'UTC',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });
});
</script>

<template>
  <div :class="['dk-stock-price', `dk-stock-price--${type}`]">
    <div class="dk-stock-price__inner">
      <div class="dk-stock-price__content">
        <div class="dk-stock-price__date">{{ dateTime }} CEST</div>
        <div class="dk-stock-price__last-price">
          <span class="dk-stock-price__label">{{
            $t('footer.stock_price.last_price')
          }}</span>
          <p class="dk-stock-price__value">{{ currency }} {{ lastPrice }}</p>
        </div>
        <div class="dk-stock-price__change">
          <span class="dk-stock-price__label">{{
            $t('footer.stock_price.change')
          }}</span>
          <p
            :class="[
              'dk-stock-price__value',
              { [`is-${changeValue}`]: changeValue },
            ]"
          >
            {{ priceChange }}
          </p>
        </div>
        <div class="dk-stock-price__isin">DOKA {{ isIn }}</div>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.dk-stock-price.dk-stock-price--country {
  @apply w-full;
  @apply bg-blue-dark;

  & .dk-stock-price__inner {
    @apply px-4;
    @apply md:px-dk-section-md;
    @apply lg:px-dk-section-lg;
    @apply xl:px-dk-section-xl;

    @apply mx-auto;
    @apply max-w-container-default;
  }

  & .dk-stock-price__content {
    @apply py-4;
    @apply border-t-2 border-white;

    @apply flex flex-row gap-3;
    @apply items-center justify-between text-center;

    @apply font-familyLight;
    @apply text-md text-white;

    @apply md:gap-14;
    @apply md:justify-center;

    @apply lg:items-start;
  }

  & .dk-stock-price__last-price,
  & .dk-stock-price__change {
    @apply flex flex-col;
    @apply lg:flex-row;
    @apply lg:gap-x-2;
  }

  & .dk-stock-price__label,
  & .dk-stock-price__label {
    @apply text-xxs;
    line-height: 1rem;

    @apply md:p-small;
    @apply md:font-familySemibold;

    @apply lg:p-large;
    @apply lg:font-familySemibold;
  }

  & .dk-stock-price__value,
  & .dk-stock-price__value {
    @apply p-small md:p-large lg:heading-6;
    @apply text-white;
    @apply whitespace-nowrap;
  }

  & .dk-stock-price__date,
  & .dk-stock-price__isin {
    @apply text-xxs leading-12;
    @apply md:p-small;
    @apply lg:p-medium;
  }
}

.dk-stock-price.dk-stock-price--group {
  @apply bg-grey-cool-5;
  @apply p-6;
  @apply border-t-2 border-grey-space;

  @apply lg:px-0 lg:py-10;

  & .dk-stock-price__content {
    @apply flex flex-col;
    @apply gap-y-4;
    @apply text-center;

    @screen lg {
      @apply max-w-container-default;
      @apply mx-auto;
      @apply flex-row;
      @apply justify-center;
      @apply text-left;
      @apply gap-x-10;
      @apply h-18;

      & > div {
        @apply h-full;
        @apply flex flex-col;

        &:not(:last-child) {
          @apply pr-10;
          @apply relative;

          &::after {
            @apply content-[''];
            @apply absolute;
            @apply h-full w-[1px];
            @apply right-0 top-0;
            @apply bg-grey-cool-20;
          }
        }
      }
    }
  }

  & .dk-stock-price__date {
    @apply p-large;
    @apply lg:justify-center;
  }

  & .dk-stock-price__label {
    @apply text-s-h7 leading-s-h7;
    @apply font-familySemibold;
    @apply text-grey-cool;
  }

  & .dk-stock-price__value {
    @apply text-h7 leading-h7;
    @apply font-familySemibold;

    @apply lg:heading-6;
  }

  & .dk-stock-price__last-price .dk-stock-price__value {
    @apply text-blue-corporate;
  }

  & .dk-stock-price__change .dk-stock-price__value {
    @apply text-grey-cool;

    &.is-positive {
      @apply text-[#166D29];
    }

    &.is-negative {
      @apply text-red-dk;
    }
  }

  & .dk-stock-price__isin {
    @apply p-medium;
    @apply lg:p-large;
    @apply lg:justify-center;

    @apply text-grey-cool;
  }
}
</style>
