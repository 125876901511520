<template>
  <div
    class="dk-megamenu dk-megamenu--fixed"
    :class="[
      {
        'dk-megamenu--primary': type === 'primary',
        'dk-megamenu--secondary': type === 'secondary',
        'dk-megamenu--full': isFull,
        'dk-megamenu--transparent': isTransparent,
        'dk-megamenu--expanded': isExpanded,
        'dk-megamenu--fix-height': fixHeight,
        'dk-megamenu--no-border': noBorder,
      },
    ]"
  >
    <slot />
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-facing-decorator';
import { MegamenuType } from '@/store/app/models/MegamenuType';

@Component
export default class Megamenu extends Vue {
  @Prop({ default: 'primary' }) type!: MegamenuType;
  @Prop() isTransparent!: boolean;
  @Prop() isExpanded!: boolean;
  @Prop() isFull!: boolean;
  @Prop() fixHeight!: boolean;
  @Prop() noBorder!: boolean;
}
</script>

<style lang="postcss">
.dk-megamenu--no-border {
  border-bottom-width: 0 !important;
}
</style>
