import {
  ProductDeclarationFragment,
  ProductDeclarationSegment,
} from '../../../types/contentful-api';
import { GenericFactory } from '@/models/factories/GenericFactory';
import { DKProductDeclarationFragment } from '@/models/DKProductDeclarationFragment';
import { DKProductDeclarationSegment } from '@/models/DKProductDeclarationSegment';
import { PropertyRequiredError } from '@/models/factories/PropertyRequiredError';
import { ProductDeclarationSegmentFactory } from '@/models/factories/ProductDeclarationSegmentFactory';

export class ProductDeclarationFragmentFactory extends GenericFactory<
  ProductDeclarationFragment,
  DKProductDeclarationFragment
> {
  contentType = 'ProductDeclarationFragment';
  requiredProperties = ['shortTitle', 'title', 'segments'];

  protected map(
    source: ProductDeclarationFragment
  ): DKProductDeclarationFragment {
    const segments: DKProductDeclarationSegment[] = [];

    if (source.segmentsCollection?.items) {
      const productDeclarationSegmentFactory =
        new ProductDeclarationSegmentFactory(this.locale);
      const items: ProductDeclarationSegment[] = source.segmentsCollection
        .items as ProductDeclarationSegment[];
      items.forEach((item: ProductDeclarationSegment) => {
        try {
          segments.push(productDeclarationSegmentFactory.create(item));
        } catch (e: any) {
          if (e instanceof PropertyRequiredError) {
            console.warn('Could not generate Segment');
            console.warn(e.message);
          } else {
            throw e;
          }
        }
      });
    }

    return Object.assign({} as DKProductDeclarationFragment, {
      ...source,
      segments,
    });
  }
}
