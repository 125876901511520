export function hasId<TValue>(
  value: TValue | null | undefined
): value is TValue {
  // @ts-ignore
  return !!value && !!value.sys?.id;
}

export const generateUniqueId = () => {
  const timestamp = Date.now().toString(36);
  const random = Math.random().toString(36).substring(2, 5);
  return timestamp + random;
};
