import { ContactSegment, Employee } from '../../../types/contentful-api';
import { GenericFactory } from '@/models/factories/GenericFactory';
import { DKContactSegment } from '@/models/DKContactSegment';
import { DKEmployee } from '@/models/DKEmployee';
import { DKLocation } from '@/models/DKLocation';
import { LocationFactory } from '@/models/factories/LocationFactory';
import { EmployeeFactory } from '@/models/factories/EmployeeFactory';
import { PropertyRequiredError } from '@/models/factories/PropertyRequiredError';

export class ContactSegmentFactory extends GenericFactory<
  ContactSegment,
  DKContactSegment
> {
  contentType = 'ContactSegment';
  requiredProperties = ['slug', 'title'];

  protected map(source: ContactSegment): DKContactSegment {
    const contacts: DKEmployee[] = [];
    const employeeFactory = new EmployeeFactory(this.locale);
    let location: DKLocation | undefined;

    if (source.contactsCollection?.items) {
      const items: Employee[] = source.contactsCollection.items as Employee[];
      items.forEach((employee: Employee) => {
        try {
          contacts.push(employeeFactory.create(employee));
        } catch (e: any) {
          if (e instanceof PropertyRequiredError) {
            console.warn('Could not generate Employee');
            console.warn(e.message);
          } else {
            throw e;
          }
        }
      });
    }

    if (source.location) {
      const locationFactory = new LocationFactory(this.locale);
      try {
        location = locationFactory.create(source.location);
      } catch (e: any) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate Location');
          console.warn(e.message);
        } else {
          throw e;
        }
      }
    }

    return Object.assign({} as DKContactSegment, {
      ...source,
      contacts,
      location,
    });
  }

  protected validate(result: DKContactSegment) {
    super.validate(result);

    if (!result.location && !result.contacts?.length) {
      throw new PropertyRequiredError(
        this.contentType,
        'Location or Contact',
        result
      );
    }
  }
}
