import { FormPage, Link } from '../../../types/contentful-api';
import { GenericFactory } from '@/models/factories/GenericFactory';
import { DKFormPage } from '@/models/DKFormPage';
import { DKLink } from '@/models/DKLink';
import { DKAsset } from '@/models/DKAsset';
import { LinkFactory } from '@/models/factories/LinkFactory';
import { PropertyRequiredError } from '@/models/factories/PropertyRequiredError';
import { AssetFactory } from '@/models/factories/AssetFactory';

export class FormPageFactory extends GenericFactory<FormPage, DKFormPage> {
  contentType = 'FormPage';
  requiredProperties = ['slug', 'title', 'formUrl', 'links'];

  protected map(source: FormPage): DKFormPage {
    let metaImage: DKAsset | undefined;
    const links: DKLink[] = [];

    if (source.linksCollection?.items) {
      const items: Link[] = source.linksCollection.items as Link[];
      const linkFactory = new LinkFactory(this.locale);

      items.forEach((link: Link) => {
        try {
          links.push(linkFactory.create(link));
        } catch (e: any) {
          if (e instanceof PropertyRequiredError) {
            console.warn('Could not generate Link');
            console.warn(e.message);
          } else {
            throw e;
          }
        }
      });
    }

    if (source.metaImage) {
      const assetFactory = new AssetFactory(this.locale);
      try {
        metaImage = assetFactory.create(source.metaImage);
      } catch (e: any) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate MetaImage');
          console.warn(e.message);
        } else {
          throw e;
        }
      }
    }

    return Object.assign({} as DKFormPage, {
      ...source,
      links,
      metaImage,
    });
  }
}
