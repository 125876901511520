import { GenericShelfElement, Link } from 'types/contentful-api';
import { GenericFactory } from '@/models/factories/GenericFactory';
import { PropertyRequiredError } from '@/models/factories/PropertyRequiredError';
import { DKGenericShelfElement } from '@/models/DKGenericShelfElement';
import { DKExtendedGenericShelfElement } from '@/models/DKExtendedGenericShelfElement';
import { DKAsset } from '@/models/DKAsset';
import { AssetFactory } from '@/models/factories/AssetFactory';
import { LinkFactory } from '@/models/factories/LinkFactory';
import { DKLink } from '@/models/DKLink';
import { DKVideoFragmentVideo } from '@/models/DKVideoFragmentVideo';
import { VideoFragmentVideoFactory } from '@/models/factories/VideoFragmentVideoFactory';

export class GenericShelfElementFactory extends GenericFactory<
  GenericShelfElement,
  DKGenericShelfElement
> {
  contentType = 'GenericShelfElement';
  requiredProperties = ['title'];

  protected map(source: DKExtendedGenericShelfElement): DKGenericShelfElement {
    let image: DKAsset | undefined;
    let genericElementLink: DKLink | undefined;
    let video: DKVideoFragmentVideo | undefined;

    if (source.image) {
      const assetFactory = new AssetFactory(this.locale);
      try {
        image = assetFactory.create(source.image);
      } catch (e: unknown) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate Image');
          console.warn(e.message);
        } else {
          throw e;
        }
      }
    }

    if (source.genericElementLink.link) {
      const linkFactory = new LinkFactory(this.locale);
      try {
        genericElementLink = linkFactory.create(
          source.genericElementLink as Link
        );
      } catch (e: any) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate Link');
          console.warn(e.message);
        } else {
          throw e;
        }
      }
    }

    if (source.video) {
      const videoFragmentVideoFactory = new VideoFragmentVideoFactory(
        this.locale
      );
      try {
        video = videoFragmentVideoFactory.create(source.video);
      } catch (e: unknown) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate Video');
          console.warn(e.message);
        } else {
          throw e;
        }
      }
    }

    return Object.assign({} as DKGenericShelfElement, {
      ...source,
      image,
      video,
      genericElementLink,
    });
  }
}
