import { getLanguageFromLocale } from '@/helpers/i18n';
import { Vue } from 'vue-facing-decorator';
import { LinkTarget } from '@/models/LinkTarget';
import { LinkTargetType } from '@/models/LinkTargetType';
import { LOCALE } from '@/store/app/models/Locale';
import { formatHash } from '@/helpers/format-hash';
import { scrollTo } from './scrollTo';

type LinkMaybe = string | null | undefined;

export const isAbsoluteLink = (link: LinkMaybe): boolean => {
  return !!link && link.includes('http');
};

export const isInternalLink = (link: LinkMaybe): boolean => {
  return !!link && link.includes(tryToGetLocationOrigin());
};

export const formatLink = (link: LinkMaybe, locale: LOCALE): string => {
  if (!link) {
    return '';
  }
  if (isAbsoluteLink(link)) {
    return link;
  }
  const currentUrlLocale = getLanguageFromLocale(locale);
  if (link.startsWith(`/${currentUrlLocale}`)) {
    return link;
  }
  return link[0] === '/'
    ? `/${currentUrlLocale}${link}`
    : `/${currentUrlLocale}/${link}`;
};

export const openLink = (
  link: LinkMaybe,
  options?: { target?: LinkTarget; hash?: string; notEncoded?: boolean }
): void => {
  if (!link) {
    return;
  }

  const encodedLink = options?.notEncoded ? link : encodeURI(link);
  const _link = options?.hash ? `${link}${options.hash}` : encodedLink;

  if (isAbsoluteLink(link) || options?.target === LinkTargetType.BLANK) {
    window.open(_link, options?.target || LinkTargetType.BLANK);
    return;
  }

  setTimeout(() => {
    options?.hash
      ? Vue.prototype.$routerProvider.push({
          path: encodedLink,
          hash: options.hash,
        })
      : Vue.prototype.$routerProvider.push(encodedLink);
  });
};

export const navigateToAnchor = (anchorHash: string): void => {
  if (!anchorHash) {
    return;
  }

  if (!anchorHash.includes('#')) {
    anchorHash = `${formatHash(anchorHash, true)}`;
  }
  scrollTo(anchorHash);
};

export const getCurrentAnchor = (withHash?: boolean): string => {
  const anchor = Vue.prototype.$routerProvider.currentRoute.value.hash;

  if (withHash) {
    return anchor;
  }

  return anchor.replace('#', '');
};

export const getSectionHashId = (
  title: string,
  withHash = false
): string | null => {
  return title ? formatHash(title, withHash) : null;
};

function tryToGetLocationOrigin(): string {
  try {
    return (window as Window).location.origin;
  } catch (err) {
    return '';
  }
}
