import fetch from 'isomorphic-unfetch';
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import { ApolloLink, NextLink, Operation } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { CreateClientParams } from 'contentful';
import isSSR from '@/_base/isSSR';
import { getEnv } from '@/env';
import { DKSpaceOriginType } from '@/models/DKSpaceOrigin';
import { globalExtraToken } from '@/helpers/resource-resolution';

const filterDeep = require('deepdash/filterDeep');
const mapValuesDeep = require('deepdash/mapValuesDeep');

const localConfig = (): CreateClientParams => ({
  basePath:
    getEnv('CONTENTFUL_GRAPHQL_API') ||
    'https://graphql.contentful.com/content/v1',
  space: getEnv('CONTENTFUL_SPACE_ID') || '',
  accessToken: getEnv('CONTENTFUL_ACCESS_TOKEN') || '',
  environment: getEnv('CONTENTFUL_ENV') || 'master',
});

const globalConfig = (): CreateClientParams => ({
  basePath:
    getEnv('CONTENTFUL_GRAPHQL_API') ||
    'https://graphql.contentful.com/content/v1',
  space: getEnv('CONTENTFUL_GLOBAL_SPACE_ID') || '',
  accessToken: getEnv('CONTENTFUL_GLOBAL_ACCESS_TOKEN') || '',
  environment: getEnv('CONTENTFUL_GLOBAL_ENVIRONMENT') || 'master',
});

export function createApolloClient(
  origin: DKSpaceOriginType
): ApolloClient<any> {
  const extraTokens = globalExtraToken();
  const config =
    origin === DKSpaceOriginType.GLOBAL_SPACE ? globalConfig() : localConfig();

  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: {
      __schema: {
        types: [
          {
            kind: 'INTERFACE',
            name: 'User',
            possibleTypes: [],
          },
        ],
      },
    },
  });

  const enhancedFetch = async (url: string, init: any) => {
    return fetch(url, {
      method: init.method,
      credentials: init.credentials,
      headers: init.headers,
      body: init.body,
    });
  };

  const httpLink = new HttpLink({
    uri: `${config.basePath}/spaces/${config.space}/environments/${config.environment}`,
    credentials: 'same-origin',
    fetch: enhancedFetch,
    headers: {
      Authorization: `Bearer ${config.accessToken}`,
      'x-contentful-resource-resolution': extraTokens,
    },
  });

  const dataLink = new ApolloLink((operation: Operation, forward: NextLink) => {
    return forward(operation).map((response) => {
      try {
        let newResponse = filterDeep(
          response,
          (value: any, key: string | number, parent: any) => {
            return !(value === null && Array.isArray(parent));
          }
        );
        newResponse = mapValuesDeep(
          newResponse,
          (value: any, key: string | number, parent: any) => {
            if (parent?.__typename?.includes('Collection') && !parent.items) {
              parent.items = [];
            }
            return value;
          }
        );
        return newResponse;
      } catch (error) {
        console.error(error);
        return response;
      }
    });
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
    }
    if (networkError) console.log(`[Network error]: ${networkError}`);
  });

  const link = dataLink.concat(errorLink.concat(httpLink));

  const cache = new InMemoryCache({ fragmentMatcher });

  // If on the client, recover the injected state
  if (!isSSR) {
    const state = window.__APOLLO_STATE__;
    if (state) {
      // If you have multiple clients, use `state.<client_id>`
      cache.restore({
        ...state.contentfulGraphQlClient,
        ROOT_QUERY: {
          ...state.contentfulGraphQlClient.ROOT_QUERY,
        },
      });
    }
    setTimeout(() => {
      delete window.__APOLLO_STATE__;
    });
  }

  return new ApolloClient({
    link,
    cache,
    ...(isSSR
      ? {
          ssrMode: true,
        }
      : {
          ssrForceFetchDelay: 100,
        }),
    defaultOptions: {
      query: {
        errorPolicy: 'all',
      },
    },
  });
}
