import { Video } from '../../../types/contentful-api';
import { GenericFactory } from '@/models/factories/GenericFactory';
import { DKVideo } from '@/models/DKVideo';
import { AssetFactory } from '@/models/factories/AssetFactory';
import { PropertyRequiredError } from '@/models/factories/PropertyRequiredError';
import { DKAsset } from '@/models/DKAsset';

export class VideoFactory extends GenericFactory<Video, DKVideo> {
  contentType = 'Video';
  requiredProperties = ['id', 'title', 'video', 'thumbnail'];

  protected map(source: Video): DKVideo {
    let id: string | undefined;
    let video: DKAsset | undefined;
    let thumbnail: DKAsset | undefined;

    if (source.sys.id) {
      id = source.sys.id;
    }

    if (source.video) {
      const assetFactory = new AssetFactory(this.locale);
      try {
        video = assetFactory.create(source.video);
      } catch (e: any) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate Video');
          console.warn(e.message);
        } else {
          throw e;
        }
      }
    }

    if (source.thumbnail) {
      const assetFactory = new AssetFactory(this.locale);
      try {
        thumbnail = assetFactory.create(source.thumbnail);
      } catch (e: any) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate Thumbnail');
          console.warn(e.message);
        } else {
          throw e;
        }
      }
    }

    return Object.assign({} as DKVideo, { ...source, id, video, thumbnail });
  }
}
