import { GenericFactory } from '@/models/factories/GenericFactory';
import { IFrameFragment } from '../../../types/contentful-api';
import { DKIFrameFragment } from '@/models/DKIFrameFragment';

export class IFrameFragmentFactory extends GenericFactory<
  IFrameFragment,
  DKIFrameFragment
> {
  contentType = 'IFrameFragment';
  requiredProperties = [
    'shortTitle',
    'url',
    'heightSm',
    'heightMd',
    'heightLg',
  ];

  protected map(source: IFrameFragment): DKIFrameFragment {
    return Object.assign({} as DKIFrameFragment, {
      ...source,
    });
  }
}
