import { NewsletterSignUpFragment } from '../../../types/contentful-api';
import { GenericFactory } from '@/models/factories/GenericFactory';
import { DKNewsletterSignUpFragment } from '@/models/DKNewsletterSignUpFragment';

export class NewsletterSignUpFragmentFactory extends GenericFactory<
  NewsletterSignUpFragment,
  DKNewsletterSignUpFragment
> {
  contentType = 'NewsletterSignUpFragment';
  requiredProperties = ['title', 'description', 'providerUrl'];

  protected map(source: NewsletterSignUpFragment): DKNewsletterSignUpFragment {
    return Object.assign({} as DKNewsletterSignUpFragment, source);
  }
}
