import { Asset, MediaGallerySegment } from '../../../types/contentful-api';
import { GenericFactory } from '@/models/factories/GenericFactory';
import { DKMediaGallerySegment } from '@/models/DKMediaGallerySegment';
import { PropertyRequiredError } from '@/models/factories/PropertyRequiredError';
import { DKAsset } from '@/models/DKAsset';
import { AssetFactory } from '@/models/factories/AssetFactory';

export class MediaGallerySegmentFactory extends GenericFactory<
  MediaGallerySegment,
  DKMediaGallerySegment
> {
  contentType = 'MediaGallerySegment';
  requiredProperties = ['title'];

  protected map(source: MediaGallerySegment): DKMediaGallerySegment {
    const assets: DKAsset[] = [];

    if (source.assetsCollection?.items) {
      const items: Asset[] = source.assetsCollection.items as Asset[];
      const assetFactory = new AssetFactory(this.locale);

      items.forEach((asset: Asset) => {
        try {
          assets.push(assetFactory.create(asset));
        } catch (e) {
          if (e instanceof PropertyRequiredError) {
            console.warn('Could not generate AssetResource');
            console.warn(e);
          } else {
            throw e;
          }
        }
      });
    }

    return Object.assign({} as DKMediaGallerySegment, {
      ...source,
      assets,
    });
  }
}
