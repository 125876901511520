import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-01c20b00"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "service-nav" }
const _hoisted_2 = { itemprop: "name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DKLink = _resolveComponent("DKLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("nav", {
      class: _normalizeClass(["service-nav__nav", { 'service-nav--is-footer': _ctx.isFooter }]),
      itemscope: "",
      itemtype: "http://schema.org/SiteNavigationElement"
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navigationTree, (navigationTreeItem) => {
        return (_openBlock(), _createBlock(_component_DKLink, {
          key: navigationTreeItem.id,
          href: navigationTreeItem.path,
          onClick: _withModifiers(($event: any) => (_ctx.onClick(navigationTreeItem)), ["prevent"]),
          class: _normalizeClass(["dk-link--title", {
          'dk-link--inverted': _ctx.isInverted,
        }]),
          type: _ctx.type,
          itemprop: "url"
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_2, _toDisplayString(navigationTreeItem.label), 1)
          ]),
          _: 2
        }, 1032, ["href", "onClick", "class", "type"]))
      }), 128))
    ], 2)
  ]))
}