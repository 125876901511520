<template>
  <div class="app-footer" itemscope itemtype="http://schema.org/WPFooter">
    <div v-if="showFooterGroup" class="app-footer__inner">
      <StockPrice
        v-if="stockPrice"
        :type="stockPriceType"
        :stockPrice="stockPrice"
        :locale="locale"
      />
      <div class="app-footer__group">
        <div id="footer-group" class="footer-group dk-layout-grid">
          <div class="footer-group__left">
            <div class="footer-group__search">
              <DKSearch
                id="footer-search"
                :placeholder="$t('footer.search.label')"
                :isInline="true"
                :isFluid="true"
                :isFooter="true"
                @search="onSearch"
              ></DKSearch>
            </div>
            <div class="footer-group__service-navigation">
              <ServiceNavigation
                :navigationTree="navigationTree"
                :isInverted="true"
                :isFooter="true"
              ></ServiceNavigation>
            </div>
          </div>
          <div class="footer-group__right">
            <div class="footer-group__contact-footer">
              <ContactFooter
                :contactFooterNavigationTree="contactFooterNavigationTree"
                @clickContactLink="onClickContactLink"
              ></ContactFooter>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer :legalNavigationTree="legalNavigationTree"></Footer>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-facing-decorator';
import ServiceNavigation from '@/components/ServiceNavigation.vue';
import Footer from '@/components/ui/Footer.vue';
import { FooterStatus } from '@/store/app/models/FooterStatus';
import { NavigationTree } from '@/store/navigation/models/NavigationTree';
import { MenuSelectType } from '@/store/app/models/MenuSelectType';
import ContactFooter from '@/components/footer/ContactFooter.vue';
import { NavigationTreeItem } from '@/store/navigation/models/NavigationTreeItem';
import { openLink } from '@/helpers/link';
import StockPrice from '@/components/footer/StockPrice.vue';
import { DKStockPrice as DKStockPriceModel } from '@dormakaba/dormakaba-components/dist/types/lib/models/DKStockPrice';
import DKSearch from '@/components/search/DKSearch.vue';
import { LOCALE } from '@/store/app/models/Locale';
import { getLanguageFromLocale } from '@/helpers/i18n';
import { DKProject } from '@/models/DKProjects';

@Component({
  components: {
    ServiceNavigation,
    Footer,
    ContactFooter,
    StockPrice,
    DKSearch,
  },
})
export default class AppFooter extends Vue {
  get showFooterGroup(): boolean {
    if (this.$route.meta.defaultFooter) {
      return this.$route.meta.defaultFooter === FooterStatus.GROUP_FOOTER;
    }
    return this.$store.getters['showFooterGroup'];
  }

  get stockPrice(): DKStockPriceModel | null {
    return this.$store.getters['stockPrice'];
  }

  get locale(): LOCALE {
    return this.$i18n.locale;
  }

  get navigationTree(): NavigationTree {
    return this.$store.getters['navigation/navigationTree'](
      MenuSelectType.SHORT_LINKS
    );
  }

  get legalNavigationTree(): NavigationTree {
    return this.$store.getters['navigation/navigationTree'](
      MenuSelectType.SUB_FOOTER
    );
  }

  get contactFooterNavigationTree(): NavigationTree {
    return this.$store.getters['navigation/navigationTree'](
      MenuSelectType.CONTACT_LINKS
    );
  }

  get stockPriceType(): DKProject {
    return DKProject.Group;
  }

  async created() {
    await this.$store.dispatch('fetchStockPrice', {
      locale: this.locale,
    });
  }

  onClickContactLink(event: NavigationTreeItem) {
    openLink(event.path);
  }

  onSearch(searchTerm: string) {
    this.$router.push({
      name: 'search-page',
      params: { locale: getLanguageFromLocale(this.$i18n.locale) },
      query: { q: searchTerm },
    });
  }
}
</script>

<style lang="postcss" scoped>
.app-footer {
  & .app-footer__group {
    @apply bg-blue-dark;
  }

  & .footer-group {
    @apply px-4 pb-10 pt-20;
    @apply gap-y-10;
    @apply max-w-container-default;
    @apply mx-auto;

    @apply md:gap-y-20;
    @apply md:px-dk-section-md;

    & .footer-group__left,
    & .footer-group__right {
      @apply col-span-full;
    }

    @screen lg {
      @apply px-dk-section-md;
      @apply py-20;

      & .footer-group__left {
        @apply col-span-7;
      }

      & .footer-group__right {
        @apply w-fit;
        @apply justify-self-end;
        @apply col-span-4 col-start-9;
      }
    }

    @apply lg:px-dk-section-lg;
    @apply xl:px-dk-section-xl;
  }
}
</style>
