import gql from 'graphql-tag';

export const localNavigationItemCollectionQuery = (popupLink: string) => gql`
  query ($locale: String, $preview: Boolean = false) {
    navigationItemCollection(
      locale: $locale
      preview: $preview
      limit: 500
    ) {
      items {
        sys {
          id
        }
        label
        link {
          sys {
            id
          }
          link
          enLink: link(locale: "en-US")
          deLink: link(locale: "de-DE")
          label
          page {
            __typename
            ... on Entry {
              sys {
                id
              }
            }
            ... on HomePage {
              sys {
                id
              }
            }
            ... on GenericPage {
              sys {
                id
              }
            }
            ... on LegalPage {
              sys {
                id
              }
            }
            ... on FormPage {
              sys {
                id
              }
            }
            ... on ReusablePage {
              sys {
                id
              }
            }
            ... on NewsDetailPage {
              sys {
                id
              }
            }
          }
        }
        childrenCollection(limit: 15) {
          items {
            sys {
              id
            }
          }
        }
        localPopup {
          sys {
            id
          }
        }
        # The locale 'de-DE' does not exist in global space. Using default locale to fetch global popup id
        popup (locale: "en-US") {
          node {
            __typename
            ... on ${popupLink} {
              sys {
                id
              }
            }
          }
        }
      }
    }
  }
`;
