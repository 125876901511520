import { GeneralMeetingFragment } from '../../../types/contentful-api';
import { GenericFactory } from '@/models/factories/GenericFactory';
import { DKGeneralMeetingFragment } from '@/models/DKGeneralMeetingFragment';

export class GeneralMeetingFragmentFactory extends GenericFactory<
  GeneralMeetingFragment,
  DKGeneralMeetingFragment
> {
  contentType = 'GeneralMeetingFragment';
  requiredProperties = ['shortTitle', 'title'];

  protected map(source: GeneralMeetingFragment): DKGeneralMeetingFragment {
    return Object.assign({} as DKGeneralMeetingFragment, source);
  }
}
