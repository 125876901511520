import { MutationTree } from 'vuex';
import { BlogState } from '@/store/blog/index';
import { DKBlogArticles } from '@/store/blog/models/DKBlogArticles';
import { LOCALE } from '@/store/app/models/Locale';

export const mutations: MutationTree<BlogState> = {
  setArticles: (
    state: BlogState,
    payload: { articles: DKBlogArticles; locale: LOCALE }
  ) => {
    state.articles = payload.articles;
    state.localizedArticles = payload.articles[payload.locale] || [];
  },

  setLocalizedArticles: (state: BlogState, payload: { locale: LOCALE }) => {
    state.localizedArticles = state.articles[payload.locale] || [];
  },
};
