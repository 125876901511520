import { HtmlCodeFragment } from '../../../types/contentful-api';
import { GenericFactory } from '@/models/factories/GenericFactory';
import { DKHtmlCodeFragment } from '@/models/DKHtmlCodeFragment';

export class HtmlCodeFragmentFactory extends GenericFactory<
  HtmlCodeFragment,
  DKHtmlCodeFragment
> {
  contentType = 'HtmlCodeFragment';
  requiredProperties = ['shortTitle', 'html'];

  protected map(source: HtmlCodeFragment): DKHtmlCodeFragment {
    return Object.assign({} as DKHtmlCodeFragment, { ...source });
  }
}
