import { ContentTypeLocation } from '../../../types/contentful-api';
import { GenericFactory } from '@/models/factories/GenericFactory';
import { DKLocation } from '@/models/DKLocation';
import { DKCoordinates } from '@/models/DKCoordinates';
import { CoordinatesFactory } from '@/models/factories/CoordinatesFactory';
import { PropertyRequiredError } from '@/models/factories/PropertyRequiredError';

export class LocationFactory extends GenericFactory<
  ContentTypeLocation,
  DKLocation
> {
  contentType = 'Location';
  requiredProperties = ['name', 'street', 'zip', 'city', 'country'];

  protected map(source: ContentTypeLocation): DKLocation {
    let coordinates: DKCoordinates | undefined;

    if (source.coordinates) {
      const coordinatesFactory = new CoordinatesFactory(this.locale);
      try {
        coordinates = coordinatesFactory.create(source.coordinates);
      } catch (e) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate Coordinates');
          console.warn(e.message);
        } else {
          throw e;
        }
      }
    }

    return Object.assign({} as DKLocation, { ...source, coordinates });
  }
}
