import { RouteLocation, Router } from 'vue-router';
import { LOCALE } from '@/store/app/models/Locale';
import { currentPath } from '@/helpers/currentPath';
import { getLanguageFromLocale } from '@/helpers/i18n';
import isSSR from '@/_base/isSSR';

export const redirectTo404 = async (
  locale: LOCALE,
  $route: RouteLocation,
  $router: Router
) => {
  const currentUrl = currentPath($route) || '/';
  await $router.replace({
    name: 'not-found-page',
    params: {
      locale: getLanguageFromLocale(locale || 'en-US'),
    },
  });
  if (!isSSR) {
    history.replaceState(history.state, '', currentUrl);
  }
  console.warn(`${currentUrl} not found!`);
};
