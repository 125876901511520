import { Video, ExternalVideo } from '../../types/contentful-api';

const replaceBaseUrl = (url: string): string =>
  url.startsWith('//') ? `https:${url}` : url;

export const videoConverter = (entry: any): Video => {
  const { sys, fields, _id } = entry;
  const { thumbnail, video } = fields;

  return {
    __typename: 'Video',
    _id,
    contentfulMetadata: {
      ...entry.metadata,
      __typename: 'ContentfulMetadata',
    },
    sys: {
      __typename: 'Sys',
      environmentId: sys.environment?.sys,
      id: sys.id,
      locale: sys.locale,
      spaceId: sys.space.id,
    },
    thumbnail: {
      __typename: 'Asset',
      sys: thumbnail.sys,
      contentfulMetadata: thumbnail.metadata,
      title: thumbnail.fields.title,
      description: thumbnail.description,
      contentType: thumbnail.fields.file.contentType,
      fileName: thumbnail.fields.file.fileName,
      size: thumbnail.fields.file.details.size,
      url: replaceBaseUrl(thumbnail.fields.file.url),
      width: thumbnail.fields.file.details.image.width,
      height: thumbnail.fields.file.details.image.height,
    },
    caption: fields.caption,
    title: fields.title,
    video: {
      __typename: 'Asset',
      sys: video.sys,
      contentfulMetadata: video.metadata,
      title: video.fields.title,
      description: video.description,
      contentType: video.fields.file.contentType,
      fileName: video.fields.file.fileName,
      size: video.fields.file.details.size,
      url: replaceBaseUrl(video.fields.file.url),
    },
  };
};

export const externalVideoConverter = (entry: any): ExternalVideo => {
  const { sys, fields, _id } = entry;
  const { thumbnail } = fields;

  return {
    __typename: 'ExternalVideo',
    _id,
    contentfulMetadata: {
      ...entry.metadata,
      __typename: 'ContentfulMetadata',
    },
    sys: {
      __typename: 'Sys',
      environmentId: sys.environment?.sys,
      id: sys.id,
      locale: sys.locale,
      spaceId: sys.space.id,
    },
    thumbnail: {
      __typename: 'Asset',
      sys: thumbnail.sys.id,
      contentfulMetadata: thumbnail.metadata,
      title: thumbnail.fields.title,
      description: thumbnail.description,
      contentType: thumbnail.fields.file.contentType,
      fileName: thumbnail.fields.file.fileName,
      size: thumbnail.fields.file.details.size,
      url: replaceBaseUrl(thumbnail.fields.file.url),
      width: thumbnail.fields.file.details.image.width,
      height: thumbnail.fields.file.details.image.height,
    },
    caption: fields.caption,
    title: fields.title,
    video: fields.video,
  };
};
