import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-40c6e2c0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "app-footer",
  itemscope: "",
  itemtype: "http://schema.org/WPFooter"
}
const _hoisted_2 = {
  key: 0,
  class: "app-footer__inner"
}
const _hoisted_3 = { class: "app-footer__group" }
const _hoisted_4 = {
  id: "footer-group",
  class: "footer-group dk-layout-grid"
}
const _hoisted_5 = { class: "footer-group__left" }
const _hoisted_6 = { class: "footer-group__search" }
const _hoisted_7 = { class: "footer-group__service-navigation" }
const _hoisted_8 = { class: "footer-group__right" }
const _hoisted_9 = { class: "footer-group__contact-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StockPrice = _resolveComponent("StockPrice")!
  const _component_DKSearch = _resolveComponent("DKSearch")!
  const _component_ServiceNavigation = _resolveComponent("ServiceNavigation")!
  const _component_ContactFooter = _resolveComponent("ContactFooter")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showFooterGroup)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.stockPrice)
            ? (_openBlock(), _createBlock(_component_StockPrice, {
                key: 0,
                type: _ctx.stockPriceType,
                stockPrice: _ctx.stockPrice,
                locale: _ctx.locale
              }, null, 8, ["type", "stockPrice", "locale"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_DKSearch, {
                    id: "footer-search",
                    placeholder: _ctx.$t('footer.search.label'),
                    isInline: true,
                    isFluid: true,
                    isFooter: true,
                    onSearch: _ctx.onSearch
                  }, null, 8, ["placeholder", "onSearch"])
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_ServiceNavigation, {
                    navigationTree: _ctx.navigationTree,
                    isInverted: true,
                    isFooter: true
                  }, null, 8, ["navigationTree"])
                ])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_ContactFooter, {
                    contactFooterNavigationTree: _ctx.contactFooterNavigationTree,
                    onClickContactLink: _ctx.onClickContactLink
                  }, null, 8, ["contactFooterNavigationTree", "onClickContactLink"])
                ])
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_Footer, { legalNavigationTree: _ctx.legalNavigationTree }, null, 8, ["legalNavigationTree"])
  ]))
}