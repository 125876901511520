import {
  EqsNewsLanguage,
  EqsNewsLanguages,
} from '@dormakaba/search-type-definitions/dist/eqs/languages';

import { ActionTree } from 'vuex';
import { AppState } from '@/store/app/index';
import { ELocales } from '@/models/Locales';
import { LOCALE } from '@/store/app/models/Locale';
import { getEnv } from '@/env';

export const actions: ActionTree<AppState, any> = {
  async fetchStockPrice({ commit, rootState }, payload: { locale: LOCALE }) {
    try {
      if (rootState.app.footer.stockPrice) {
        return;
      }

      const language: EqsNewsLanguage =
        payload.locale === ELocales.DE
          ? EqsNewsLanguages.GERMAN
          : EqsNewsLanguages.ENGLISH;

      const stockPriceUrl = getEnv('EQS_STOCK_PRICE_URL');
      const baseStockPriceUrl = stockPriceUrl.replace('{language}', language);
      const response = await fetch(baseStockPriceUrl);

      const stockPrice = await response.json();

      commit('setStockPrice', stockPrice);
    } catch (e) {
      console.error('Could not find stock price!');
      console.error(e);
    }
  },
};
