import { GetterTree } from 'vuex';
import { SearchState } from '@/store/search/index';
import { DKUnifiedSearchResult } from '@/store/search/models/DKUnifiedSearchResult';
import { EqsNewsItem } from '@dormakaba/search-type-definitions/dist/eqs/item';

export const getters: GetterTree<SearchState, any> = {
  isLoading: (state: SearchState): boolean => {
    return state.searchResults.loading;
  },
  searchResults: (state: SearchState): DKUnifiedSearchResult[] => {
    return state.searchResults.data;
  },
  hasMore: (state: SearchState): boolean => {
    return state.searchResults.hasMore;
  },
  newsResults: (state: SearchState): EqsNewsItem[] => {
    return state.newsResults.data;
  },
  isNewsLoading: (state: SearchState): boolean => {
    return state.newsResults.loading;
  },
  hasMoreNews: (state: SearchState): boolean => {
    return state.newsResults.hasMore;
  },
};
