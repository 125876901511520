import isSSR from '@/_base/isSSR';

function headerNavbarHeight(targetTop?: number): number {
  const navbarWrapper = document.querySelector(
    '.dk-header .dk-navbar'
  ) as HTMLElement;

  const isNotVisible = targetTop
    ? targetTop > window.scrollY
    : navbarWrapper && navbarWrapper.getBoundingClientRect().top < 0;

  return navbarWrapper && !isNotVisible ? getElementHeight(navbarWrapper) : 0;
}

export function pageHeaderNavbarHeight(): number {
  const pageNavbarWrapper = document.querySelector(
    '.dk-page-header .dk-page-navbar'
  ) as HTMLElement;
  return pageNavbarWrapper ? getElementHeight(pageNavbarWrapper) - 5 : 0;
}

export function pageJumpMarksWrapper(): HTMLElement {
  return document.querySelector(
    '.dk-page-navbar.dk-page-navbar--sticky'
  ) as HTMLElement;
}

export function pageHeaderJumpMarksHeight(): number {
  return pageJumpMarksWrapper() ? getElementHeight(pageJumpMarksWrapper()) : 0;
}

export function searchFieldHeight(): number {
  const searchFieldWrapper = document.querySelector(
    '.search-page .search-area'
  ) as HTMLElement;
  return searchFieldWrapper ? getElementHeight(searchFieldWrapper) : 0;
}

export function breadcrumbHeight(): number {
  const breadcrumbWrapper = document.querySelector(
    '.dk-breadcrumb'
  ) as HTMLElement;
  return breadcrumbWrapper ? getElementHeight(breadcrumbWrapper) : 0;
}

export function headerHeight(targetTop?: number): number {
  return !isSSR
    ? headerNavbarHeight(targetTop) +
        pageHeaderNavbarHeight() +
        pageHeaderJumpMarksHeight() +
        searchFieldHeight() +
        breadcrumbHeight()
    : 0;
}

export function footerHeight(): number {
  const footerWrapper = document.querySelector('.app-footer') as HTMLElement;
  return footerWrapper
    ? parseInt(window.getComputedStyle(footerWrapper).height, 10)
    : 0;
}

export function idSelectorByHash(hash: string): number {
  const cleanedHash = hash.startsWith('#') ? hash.slice(1) : hash;

  const retrievedElement = document.querySelector(
    `*[id="${cleanedHash}"]`
  ) as HTMLElement;
  return retrievedElement ? retrievedElement.offsetTop : 0;
}

export function getElementHeight(e: HTMLElement): number {
  return parseInt(window.getComputedStyle(e).height, 10);
}
