import { GenericFactory } from '@/models/factories/GenericFactory';
import { DKNewsItemAttachment } from '@/models/DKNewsItemAttachment';
import { EqsNewsItemAttachment } from '@dormakaba/search-type-definitions/dist/eqs/item';

export class EqsNewsAttachmentFactory extends GenericFactory<
  EqsNewsItemAttachment,
  DKNewsItemAttachment
> {
  contentType = 'EqsNewsAttachment';
  requiredProperties = ['title', 'mime_type', 'url'];

  protected map(source: EqsNewsItemAttachment): DKNewsItemAttachment {
    return Object.assign({} as DKNewsItemAttachment, source);
  }
}
