import gql from 'graphql-tag';

export const localReferenceCollectionQuery = (referenceLink: string) => gql`
query (
  $locale: String = "en-US"
  $preview: Boolean = false
  $where: LocalReferenceFilter
) {
  localReferenceCollection(
    locale: $locale
    preview: $preview
    where: $where
    limit: 20
  ) {
    items {
      sys {
        id
      }
      # The locale 'de-DE' does not exist in global space. Using default locale to fetch global reference id
      reference (locale: "en-US") {
        node {
          __typename
          ... on ${referenceLink} {
            sys {
              id
            }
          }
        }
      }
      callToActionLink {
        link
        enLink: link(locale: "en-US")
        deLink: link(locale: "de-DE")
        label
      }
    }
  }
}`;
