import { Vue } from 'vue-facing-decorator';
import { DKSpaceOrigin, DKSpaceOriginType } from '@/models/DKSpaceOrigin';

export const globalContentfulGraphQlClient = () =>
  Vue.prototype.$apolloProvider.clients.contentfulGraphQlGlobalClient;

export const localContentfulGraphQlClient = () =>
  Vue.prototype.$apolloProvider.clients.contentfulGraphQlClient;

export const getContentfulGraphQlApolloClient = (origin: DKSpaceOrigin) => {
  return origin === DKSpaceOriginType.GLOBAL_SPACE
    ? globalContentfulGraphQlClient
    : localContentfulGraphQlClient;
};
