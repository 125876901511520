import {
  PortfolioFragment,
  ProductCluster,
  Service,
} from '../../../types/contentful-api';
import { GenericFactory } from '@/models/factories/GenericFactory';
import { DKPortfolioFragment } from '@/models/DKPortfolioFragment';
import { DKService } from '@/models/DKService';
import { PropertyRequiredError } from '@/models/factories/PropertyRequiredError';
import { ProductClusterFactory } from '@/models/factories/ProductClusterFactory';
import { DKProductCluster } from '@/models/DKProductCluster';
import { ServiceFactory } from '@/models/factories/ServiceFactory';

export class PortfolioFragmentFactory extends GenericFactory<
  PortfolioFragment,
  DKPortfolioFragment
> {
  contentType = 'PortfolioFragment';
  requiredProperties = ['shortTitle', 'title'];

  protected map(source: PortfolioFragment): DKPortfolioFragment {
    const productClusters: DKProductCluster[] = [];
    const services: DKService[] = [];

    if (source.productClustersCollection?.items) {
      const productClusterFactory = new ProductClusterFactory(this.locale);
      const items: ProductCluster[] = source.productClustersCollection
        .items as ProductCluster[];
      items.forEach((item: ProductCluster) => {
        try {
          productClusters.push(productClusterFactory.create(item));
        } catch (e: any) {
          if (e instanceof PropertyRequiredError) {
            console.warn('Could not generate ProductCluster');
            console.warn(e.message);
          } else {
            throw e;
          }
        }
      });
    }

    if (source.servicesCollection?.items) {
      const serviceFactory = new ServiceFactory(this.locale);
      const items: Service[] = source.servicesCollection.items as Service[];
      items.forEach((item: Service) => {
        try {
          services.push(serviceFactory.create(item));
        } catch (e: any) {
          if (e instanceof PropertyRequiredError) {
            console.warn('Could not generate Service');
            console.warn(e.message);
          } else {
            throw e;
          }
        }
      });
    }

    return Object.assign({} as DKPortfolioFragment, {
      ...source,
      productClusters,
      services,
    });
  }
}
