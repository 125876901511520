import { AssetFactory } from '@/models/factories/AssetFactory';
import { DKAsset } from '@/models/DKAsset';
import { DKQuote } from '@/models/DKQuote';
import { GenericFactory } from '@/models/factories/GenericFactory';
import { PropertyRequiredError } from '@/models/factories/PropertyRequiredError';
import { Quote } from '../../../types/contentful-api';

export class QuoteFactory extends GenericFactory<Quote, DKQuote> {
  contentType = 'Quote';
  requiredProperties = ['quote', 'firstName', 'lastName', 'function'];

  protected map(source: Quote): DKQuote {
    let image: DKAsset | undefined;

    if (source.image) {
      const assetFactory = new AssetFactory(this.locale);
      try {
        image = assetFactory.create(source.image);
      } catch (e: any) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate Image');
          console.warn(e.message);
        } else {
          throw e;
        }
      }
    }

    return Object.assign({} as DKQuote, { ...source, image, id: source._id });
  }
}
