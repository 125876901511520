import { GenericFactory } from '@/models/factories/GenericFactory';
import { VerticalSolutionPortfolio } from '../../../types/contentful-api';
import { DKVerticalSolutionPortfolio } from '@/models/DKVerticalSolutionPortfolio';

export class VerticalSolutionPortfolioFactory extends GenericFactory<
  VerticalSolutionPortfolio,
  DKVerticalSolutionPortfolio
> {
  contentType = 'Solution';
  requiredProperties = ['title'];

  protected map(
    source: VerticalSolutionPortfolio
  ): DKVerticalSolutionPortfolio {
    return Object.assign({} as DKVerticalSolutionPortfolio, {
      ...source,
    });
  }
}
