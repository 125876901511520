import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "dk-navbar__inner" }
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "dk-navbar__nav" }
const _hoisted_4 = {
  key: 1,
  class: "dk-navbar__nav-actions"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DKLogo = _resolveComponent("DKLogo")!

  return (_openBlock(), _createElementBlock("div", {
    itemprop: "hasPart",
    itemscope: "",
    itemtype: "http://schema.org/SiteNavigationElement",
    class: _normalizeClass([
      'dk-navbar',
      `dk-navbar--${_ctx.type}`,
      {
        'dk-navbar--open': _ctx.isOpen,
      },
    ])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (!_ctx.isOpen)
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            class: "dk-navbar__link dk-navbar__logo",
            href: _ctx.homeHref,
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('clickHome')), ["prevent"])),
            itemprop: "url"
          }, [
            _createVNode(_component_DKLogo, {
              type: "group",
              color: _ctx.logoColor,
              itemprop: "logo"
            }, null, 8, ["color"])
          ], 8, _hoisted_2))
        : _createCommentVNode("", true),
      _createElementVNode("ul", _hoisted_3, [
        _renderSlot(_ctx.$slots, "default")
      ]),
      (_ctx.isDesktop)
        ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
            _renderSlot(_ctx.$slots, "actions")
          ]))
        : _createCommentVNode("", true)
    ]),
    _renderSlot(_ctx.$slots, "brand")
  ], 2))
}