import { TabItem } from '@/models/DKTabs';

export enum DKEventStatus {
  Upcoming = 'upcoming',
  Past = 'past',
}

export interface DKEventTabItem extends TabItem {
  id: DKEventStatus;
}
