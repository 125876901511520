import { GenericFactory } from '@/models/factories/GenericFactory';
import { PropertyRequiredError } from '@/models/factories/PropertyRequiredError';
import { Popup } from '../../../types/contentful-api';
import { DKAsset } from '@/models/DKAsset';
import { AssetFactory } from '@/models/factories/AssetFactory';
import { DKLink } from '@/models/DKLink';
import { LinkFactory } from '@/models/factories/LinkFactory';
import { DKPopup } from '@/models/DKPopup';

export class PopupFactory extends GenericFactory<Popup, DKPopup> {
  contentType = 'Popup';
  requiredProperties = ['title', 'link', 'recurrence'];

  protected map(source: Popup): DKPopup {
    const assetFactory = new AssetFactory(this.locale);
    const linkFactory = new LinkFactory(this.locale);

    let link: DKLink | undefined;
    let image: DKAsset | undefined;

    if (source.link) {
      try {
        link = linkFactory.create(source.link);
      } catch (e: unknown) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate Link Asset within PopupFactory');
          console.warn(e.message);
        } else {
          throw e;
        }
      }
    }

    if (source.image) {
      try {
        image = assetFactory.create(source.image);
      } catch (e: unknown) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate Image Asset within PopupFactory');
          console.warn(e.message);
        } else {
          throw e;
        }
      }
    }

    return Object.assign({} as DKPopup, {
      ...source,
      id: source?.sys.id,
      link,
      image,
    });
  }
}
