import { LegalPage } from '../../../types/contentful-api';
import { GenericFactory } from '@/models/factories/GenericFactory';
import { DKLegalPage } from '@/models/DKLegalPage';
import { DKAsset } from '@/models/DKAsset';
import { AssetFactory } from '@/models/factories/AssetFactory';
import { PropertyRequiredError } from '@/models/factories/PropertyRequiredError';

export class LegalPageFactory extends GenericFactory<LegalPage, DKLegalPage> {
  contentType = 'LegalPage';
  requiredProperties = ['slug', 'title', 'body'];

  protected map(source: LegalPage): DKLegalPage {
    let metaImage: DKAsset | undefined;

    if (source.metaImage) {
      const assetFactory = new AssetFactory(this.locale);
      try {
        metaImage = assetFactory.create(source.metaImage);
      } catch (e: any) {
        if (e instanceof PropertyRequiredError) {
          console.warn('Could not generate MetaImage');
          console.warn(e.message);
        } else {
          throw e;
        }
      }
    }

    return Object.assign({} as DKLegalPage, { ...source, metaImage });
  }
}
