<template>
  <ul
    itemscope
    itemtype="http://schema.org/SiteNavigationElement"
    class="contact-button-list"
  >
    <li
      class="contact-button-list__list-item"
      v-for="contactNavigationTreeItem of contactNavigationTree"
      :key="contactNavigationTreeItem.id"
    >
      <DKLink
        class="dk-link--title"
        :class="{
          'dk-link--inverted': isInverted,
        }"
        @click.prevent="$emit('clickContactLink', contactNavigationTreeItem)"
        :href="contactNavigationTreeItem.path"
        :target="contactNavigationTreeItem.target"
        itemprop="url"
      >
        <span itemprop="name">
          {{ contactNavigationTreeItem.label }}
        </span>
      </DKLink>
    </li>
  </ul>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-facing-decorator';
import { NavigationTree } from '@/store/navigation/models/NavigationTree';
import { DKLink } from '@dormakaba/dormakaba-components';

@Component({
  components: {
    DKLink,
  },
  emits: ['clickContactLink'],
})
export default class ContactButtonList extends Vue {
  @Prop() contactNavigationTree!: NavigationTree;
  @Prop({ default: false }) isInverted!: boolean;
}
</script>

<style lang="postcss" scoped>
.contact-button-list {
  @apply grid grid-cols-2;
  @apply gap-x-4 gap-y-2;
  @apply w-full;

  @apply lg:grid-cols-1;
  @apply lg:gap-y-4;

  & .contact-button-list__list-item {
    @apply font-familyLight;
    @apply p-small;

    @apply md:p-medium;

    & :deep(.dk-link:hover) > :first-child {
      @apply text-grey-frigate no-underline !important;
    }
  }
}
</style>
