import { createSSRApp, createApp, h } from 'vue';
import isSSR from '@/_base/isSSR';
import App from './App.vue';
import createRouter from './router';
import { Vue3Mq } from 'vue3-mq';
import * as vuexStore from './store/useVuexStore';
import * as apolloStore from './store/useApolloStore';
import { getCurrentLocale } from '@/helpers/i18n';
import { Vue } from 'vue-facing-decorator';
import { svgSpritePlugin } from 'vue-svg-sprite';
import { VueCookieNext } from 'vue-cookie-next';
import { trackView, trackFragment, trackInPage } from '@/helpers/google';
import { getSectionHashId } from '@/helpers/link';
import navigationGuard from '@/router/navigation-guard';
import Vue3Autocounter from 'vue3-autocounter';
import VueSplide from '@splidejs/vue-splide';
import ClickOutside from './directives/ClickOutside';

import {
  createMetaManager,
  deepestResolver,
  defaultConfig,
  plugin as vueMetaPlugin,
} from 'vue-meta';

import DormakabaComponents, {
  isMobile,
  isTablet,
  isDesktop,
  prettyBytes,
  delayClass,
} from '@dormakaba/dormakaba-components';

// styles
import '@splidejs/vue-splide/css/core';
import '@/assets/styles/main.pcss';
import '@dormakaba/dormakaba-ui/dist/dormakaba-ui.css';
import '@dormakaba/dormakaba-components/dist/style.css';

type ARGS = {
  vuexStore: any;
  apolloStore: any;
  apolloProvider: any;
  i18n: any;
};

// register custom property types globally
declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $mq: any;
    $refs: any;
    $store: any;
    $metaInfoRef: any;
    $prettyBytes: any;
    $getSectionHashId: any;
    $locale: any;
    $trackFragment: any;
    $trackView: any;
    $trackInPage: any;
    $isMobile: any;
    $isTablet: any;
    $isDesktop: any;
    $delayClass: any;
  }
}

export default function (args: ARGS) {
  const rootComponent = {
    render: () => h(App),
    components: { App },
    setup() {
      vuexStore.provideStore(args.vuexStore);
      apolloStore.provideStore(args.apolloStore);
    },
  };

  const i18n = args.i18n;
  const locale = args.i18n.global.locale;
  Vue.prototype.$t = i18n.global.t;

  const app = (isSSR ? createSSRApp : createApp)(rootComponent);
  const router = createRouter(i18n);
  const routerGuard = navigationGuard.bind({
    $store: args.vuexStore,
    $router: router,
  });

  const metaManager = createMetaManager(isSSR, defaultConfig, deepestResolver);

  Vue.prototype.$apolloProvider = args.apolloProvider;

  app.config.globalProperties.$prettyBytes = prettyBytes;
  app.config.globalProperties.$getSectionHashId = getSectionHashId;
  app.config.globalProperties.$locale = getCurrentLocale;
  app.config.globalProperties.$trackFragment = trackFragment;
  app.config.globalProperties.$trackView = trackView;
  app.config.globalProperties.$trackInPage = trackInPage;
  app.config.globalProperties.$isMobile = isMobile;
  app.config.globalProperties.$isTablet = isTablet;
  app.config.globalProperties.$isDesktop = isDesktop;
  app.config.globalProperties.$delayClass = delayClass;

  app
    .use(DormakabaComponents, {
      svgSpriteUrl: '/svg/sprite.symbol.svg',
      locale: locale,
      translate: i18n.global.t,
    })
    .use(args.vuexStore)
    .use(i18n)
    .use(metaManager)
    .use(vueMetaPlugin)

    .use(VueCookieNext)
    .use(svgSpritePlugin, {
      /* temporary solution */
      url: '/svg/sprite.symbol.svg',
    })
    .use(Vue3Autocounter)
    .use(VueSplide)
    .use(Vue3Mq, {
      breakpoints: {
        xs: 0,
        sm: 640,
        md: 768,
        lg: 1280,
        xl: 1480,
        xxl: 1920,
      },
      defaultBreakpoint: 'md',
    })
    .directive('click-outside', ClickOutside);

  VueCookieNext.config({ expire: '30d' });

  return {
    app,
    router,
    routerGuard,
  };
}
