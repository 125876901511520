import {
  Management,
  ManagementGallerySegment,
} from '../../../types/contentful-api';
import { GenericFactory } from '@/models/factories/GenericFactory';
import { DKManagementGallerySegment } from '@/models/DKManagementGallerySegment';
import { DKManagement } from '@/models/DKManagement';
import { ManagementFactory } from '@/models/factories/ManagementFactory';
import { PropertyRequiredError } from '@/models/factories/PropertyRequiredError';

export class ManagementGallerySegmentFactory extends GenericFactory<
  ManagementGallerySegment,
  DKManagementGallerySegment
> {
  contentType = 'ManagementGallerySegment';
  requiredProperties = ['title'];

  protected map(source: ManagementGallerySegment): DKManagementGallerySegment {
    const management: DKManagement[] = [];

    if (source.managementCollection?.items) {
      const items: Management[] = source.managementCollection
        .items as Management[];
      const managementFactory = new ManagementFactory(this.locale);

      items.forEach((manager: Management) => {
        try {
          management.push(managementFactory.create(manager));
        } catch (e) {
          if (e instanceof PropertyRequiredError) {
            console.warn('Could not generate Management');
            console.warn(e);
          } else {
            throw e;
          }
        }
      });
    }

    return Object.assign({} as DKManagementGallerySegment, {
      ...source,
      management,
    });
  }
}
