import { GenericFactory } from '@/models/factories/GenericFactory';
import { Fact } from '../../../types/contentful-api';
import { DKFact } from '@/models/DKFact';

export class FactFactory extends GenericFactory<Fact, DKFact> {
  contentType = 'Fact';
  requiredProperties = ['fact'];

  protected map(source: Fact): DKFact {
    return Object.assign({} as DKFact, {
      ...source,
    });
  }
}
