import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["dk-megamenu dk-megamenu--fixed", [
      {
        'dk-megamenu--primary': _ctx.type === 'primary',
        'dk-megamenu--secondary': _ctx.type === 'secondary',
        'dk-megamenu--full': _ctx.isFull,
        'dk-megamenu--transparent': _ctx.isTransparent,
        'dk-megamenu--expanded': _ctx.isExpanded,
        'dk-megamenu--fix-height': _ctx.fixHeight,
        'dk-megamenu--no-border': _ctx.noBorder,
      },
    ]])
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}